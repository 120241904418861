<template>
  <div class="watch">
    <div class="code-aside-bar">
      <div class="slide_wrap">
        <ul class="slide_b">
          <li
            v-for="(item, index) in time_table_tab"
            :key="item.id"
            v-if="item.is_show"
            @mouseenter="handleSlideOver(item.id)"
            @click="handleSlide(item.id)"
            :class="[
              item.id == time_table_current_id ? 'on' : '',
              item.id == 6 && notice_before ? 'notice_img' : '',
            ]"
          >
            <img
              :src="item.id == time_table_current_id ? item.url_on : item.url"
              alt=""
            />
            <p>{{ item.title }}</p>
          </li>
        </ul>
        <!-- 1点播  2直播 3讲义  4QQ群 5微信  6练习 7作业 -->
        <div
          v-if="time_table_isshow"
          class="time_table"
          @mouseleave="handleSlideOut()"
        >
          <div
            v-for="(item, index) in time_table"
            :key="item.id"
            v-if="item.section.length"
            class="chapter"
          >
            <div class="chapter_tit" @click="changeShow(item.id)">
              <div class="chapter_name">
                {{ item.chapter_name }}
              </div>
              <div class="chapter_isrecently_study">
                <div class="down_up_down" v-show="item.is_show">
                  收起<img class="right_jiao" src="@/assets/course/down.png" />
                </div>
                <div class="down_up" v-show="!item.is_show">
                  展开<img class="right_jiao" src="@/assets/course/right.png" />
                </div>
                <div
                  v-if="recently_chapter_id == item.id"
                  class="recently_study"
                >
                  最近学习
                </div>
              </div>
            </div>
            <div class="section" v-show="item.is_show">
              <div v-for="(sec, sec_ind) in item.section" :key="sec.id">
                <div
                  :class="[
                    'section_every',
                    sec_id == sec.id || right_show_sec.id == sec.id
                      ? 'current_sec'
                      : '',
                  ]"
                  @click="handleClickSection(sec)"
                >
                  <div class="section_tit">
                    <div
                      v-if="sec_id == sec.id || right_show_sec.id == sec.id"
                      class="section_number_img"
                    >
                      <img src="@/assets/course/current_play.png" />
                    </div>
                    <div v-else class="section_number">
                      <!-- {{sec_ind+1 | add0}} -->{{ sec.sec_index | add0 }}
                    </div>
                    <div class="section_name">{{ sec.section_name }}</div>
                  </div>
                  <div class="section_bot">
                    <!--  [1点播]  2直播 3讲义  4QQ群 5微信  6练习 7作业 -->
                    <div
                      v-if="sec.section_type == 1"
                      :class="[
                        'sec_b',
                        sec.section_is_online && sec.section_audition&&!course.is_join
                          ? 'try_color'
                          : '',
                      ]"
                    >
                      <div class="sec_type">
                        <img
                          class="type_video"
                          :src="
                            sec.section_audition&&!course.is_join
                              ? require('@/assets/course/video_color.png')
                              : require('@/assets/course/type_video.png')
                          "
                        />
                        <div>{{ sec.section_audition&&!course.is_join ? "试听" : "视频" }}</div>
                        <template v-if="sec.section_is_online&&sec.section_duration">
                          <b>|</b>
                          <div>{{ sec.section_duration }}</div>
                        </template>
                      </div>
                      <div class="sec_status">
                        <div v-if="course.is_join">
                          <div v-if="sec.section_is_online">
                            <!-- <div v-if="sec.section_audition&&!course.is_join" class="try_study">
                              试听
                            </div> -->
                            <div class="video_play">播放</div>
                          </div>
                          <div v-else class="video_noline">未上线</div>
                        </div>
                        <div v-else>
                          <template v-if="course.course_is_free">
                            <div v-if="sec.section_audition">试听</div>
                            <img v-else src="@/assets/course/lock.png" />
                          </template>
                          <div v-else>播放</div>
                        </div>
                      </div>
                    </div>
                    <!--  1点播  [2直播] 3讲义  4QQ群 5微信  6练习 7作业 -->
                    <div class="sec_b" v-if="sec.section_type == 2">
                      <div class="sec_type">
                        <img
                          class="type_live"
                          src="@/assets/course/type_live.png"
                        />
                        <div>直播</div>
                        <template v-if="sec.section_time">
                          <b>|</b>
                          <div>{{ sec.section_time }}</div>
                        </template>
                      </div>
                      <!-- 直播状态 0点播使用    1 未开始 2直播中  3直播结束  4回放  5无回放） -->
                      <div class="sec_status">
                        <div v-if="course.is_join">
                          <div v-if="sec.section_status == 1" class="subscribe">
                            未开始
                          </div>
                          <div v-if="sec.section_status == 2" class="living">
                            <img
                              class="living_icon"
                              src="@/assets/course/living.gif"
                            />
                            直播中
                          </div>
                          <div v-if="sec.section_status == 3" class="live_end">
                            已结束
                          </div>
                          <div v-if="sec.section_status == 4" class="playback">
                            回放
                          </div>
                          <div
                            v-if="sec.section_status == 5"
                            class="noplayback"
                          >
                            无回放
                          </div>
                        </div>
                        <div v-else>
                          <template v-if="course.course_is_free">
                            <template v-if="sec.section_audition">
                              <div v-if="sec.section_status == 1" class="subscribe">
                                未开始
                              </div>
                              <div v-if="sec.section_status == 2" class="living">
                                <img
                                  class="living_icon"
                                  src="@/assets/course/living.gif"
                                />
                                直播中
                              </div>
                              <div v-if="sec.section_status == 3" class="live_end">
                                已结束
                              </div>
                              <div v-if="sec.section_status == 4" class="playback">
                                回放
                              </div>
                              <div
                                v-if="sec.section_status == 5"
                                class="noplayback"
                              >
                                无回放
                              </div>
                            </template>
                            <img v-else src="@/assets/course/lock.png" />
                          </template>
                          <template v-else>
                            <div v-if="sec.section_status == 1" class="subscribe">
                              未开始
                            </div>
                            <div v-if="sec.section_status == 2" class="living">
                              <img
                                class="living_icon"
                                src="@/assets/course/living.gif"
                              />
                              直播中
                            </div>
                            <div v-if="sec.section_status == 3" class="live_end">
                              已结束
                            </div>
                            <div v-if="sec.section_status == 4" class="playback">
                              回放
                            </div>
                            <div
                              v-if="sec.section_status == 5"
                              class="noplayback"
                            >
                              无回放
                            </div>
                          </template>
                          <!-- <div v-if="sec.section_audition" class="try_color">试听</div>
                          <img v-else src="@/assets/course/lock.png" /> -->
                        </div>
                      </div>
                    </div>
                    <!--  1点播  2直播 [3讲义]  4QQ群 5微信  6练习 7作业 -->
                    <div class="sec_b" v-if="sec.section_type == 3">
                      <div class="sec_type">
                        <img
                          class="type_pdf"
                          src="@/assets/course/type_pdf.png"
                        />
                        <div>讲义</div>
                      </div>
                      <div class="sec_status">
                        <template v-if="course.is_join">
                          <template v-if="sec.section_is_online">
                            <div class="btn">查看</div>
                          </template>
                          <div v-else class="btn">未上线</div>
                        </template>
                        <template v-else>
                          <template v-if="course.course_is_free">
                            <div v-if="sec.section_audition" class="btn">
                              试听
                            </div>
                            <div v-else class="">
                              <img src="@/assets/course/lock.png" alt="" />
                            </div>
                          </template>
                          <div v-else class="btn">
                            查看
                          </div>
                        </template>
                        
                        <!-- <div v-if="course.is_join">查看</div>
                        <div v-else>
                          <div v-if="sec.section_audition" class="try_color">查看</div>
                          <img v-else src="@/assets/course/lock.png" />
                        </div> -->
                      </div>
                    </div>
                    <!--  1点播  2直播 3讲义  [4QQ群] 5微信  6练习 7作业 -->
                    <div class="sec_b" v-if="sec.section_type == 4">
                      <div class="sec_type">
                        <img
                          class="type_code"
                          src="@/assets/course/type_code.png"
                        />
                        <div>qq</div>
                      </div>
                      <div v-if="course.is_join">查看</div>
                      <div v-else>
                        <img src="@/assets/course/lock.png" />
                      </div>
                    </div>
                    <!--  1点播  2直播 3讲义  4QQ群 [5微信]  6练习 7作业 -->
                    <div class="sec_b" v-if="sec.section_type == 5">
                      <div class="sec_type">
                        <img
                          class="type_code"
                          src="@/assets/course/type_code.png"
                        />
                        <div>二维码</div>
                      </div>
                      <div class="sec_status">
                        <div v-if="course.is_join">查看</div>
                        <div v-else>
                          <img src="@/assets/course/lock.png" />
                        </div>
                      </div>
                    </div>
                    <!--  1点播  2直播 3讲义  4QQ群 5微信  [6练习] 7作业 -->
                    <div class="sec_b" v-if="sec.section_type == 6">
                      <div class="sec_type">
                        <img
                          class="type_practie"
                          src="@/assets/course/type_practie.png"
                        />

                        <div>练习</div>
                      </div>
                      <div class="sec_status">
                        <div v-if="course.is_join">查看</div>
                        <div v-else>
                          <img src="@/assets/course/lock.png" />
                        </div>
                      </div>
                    </div>
                    <!--  1点播  2直播 3讲义  4QQ群 5微信  6练习 [7作业] -->
                    <div class="sec_b" v-if="sec.section_type == 7">
                      <div class="sec_type">
                        <img
                          class="type_homework"
                          src="@/assets/course/type_homework.png"
                        />

                        <div>作业</div>
                        <b>|</b>
                        <div>
                          {{ sec.sectionTask.is_task ? "已完成" : "未完成" }}
                        </div>
                      </div>
                      <div class="sec_status">
                        <div v-if="course.is_join">
                          {{ sec.sectionTask.task_num }}人提交
                        </div>
                        <div v-else>
                          <img src="@/assets/course/lock.png" />
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        sec.section_type == 7 &&
                        sec.sectionTask.task_status == 1
                      "
                      class="homework_iscorrect"
                    >
                      已批改
                    </div>
                  </div>
                  <div
                    v-if="
                      recently_chapter_id == item.id &&
                      recently_sec_id == sec.id
                    "
                    class="recently_study sec_recently"
                  >
                    最近学习
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="code-layout">
      <div class="top_slide">
        <div class="left">
          {{ course.course_name }}（{{ course.course_tag }}）
        </div>
        <div class="right">
          <a
            href="http://www.successkaoyan.com/special/successapp/"
            target="blank"
          >
            <div class="app">客户端下载</div>
          </a>
          <div class="my_wrap" v-if="logined">
            <i></i>
            <a href="javascript:;" active-class="active_class" @click="handleToPage('/mine/my_course')"
              >我的课程</a>
            <a href="javascript:;" class="userlogo" active-class="active_class"
              ><img :src="user.userlogo" alt=""
            />
              <div class="logout_wrap">
                <a href="javascript:;"  @click="handleToPage('/mine/my_center')">个人中心</a>
                <div class="login_out_btn" @click="login_out">退出</div>
              </div>
            </a>
          </div>
          <div v-else class="btn_login" @click="$router.push('login')">
            注册/登录
          </div>
        </div>
      </div>
      <div id="webide-layout">
        <div v-if="left_show_type" class="col-left" id="col-left">
          <div class="inner">
            <!-- 1点播  2直播 3讲义  4QQ群 5微信  6练习 7作业 -->
            <div v-show="left_show_type == 'play'" class="pannel video_pannel">
              <div class="pannel_top">
                <div class="sec_name">{{ sec_name }}</div>
              </div>

              <!-- 控制头部显示【0解锁、1视频节、2直播提示、3讲义提示、4群提示、6练习、7作业、player播放器】 -->
              <template v-if="bg_tip_status">
                <div
                  v-show="bg_tip_status == 'player'"
                  class=""
                  id="player"
                  ref="video_player"
                ></div>
                <div v-show="bg_tip_status == 1" class="play_bg_tip">
                  <div class="title">
                    <img
                      class="type_img"
                      src="@/assets/course/lock_white.png"
                    />
                    <p>视频节</p>
                  </div>
                  <div class="bg_tips_status">未上线</div>
                </div>
                <div v-show="bg_tip_status == 3" class="play_bg_tip">
                  <div class="title">
                    <img
                      class="type_img"
                      src="@/assets/course/lock_white.png"
                    />
                    <p>讲义节</p>
                  </div>
                  <div class="bg_tips_status">未上线</div>
                </div>
              </template>
              <div v-else class="play_bg_tip">
                <div class="title">
                  <img class="type_img" src="@/assets/course/lock_white.png" />
                  <p>购买后解锁</p>
                </div>
                <div @click="toOrder" class="bg_tips_status">立即购买</div>
              </div>
            </div>
            <div v-show="left_show_type == 'live'" class="pannel live_pannel">
              <div class="pannel_top">
                <div class="sec_name">{{ sec_name }}</div>
              </div>
              <!-- <div
                v-show="sec.section_status == 2"
                id="id_test_video"
                ref="id_test_video"
                class="id_test_video"
              >
                <div class="liveall flash_tips" v-if="flash_tips_show">
                    <img src="//livestatic.videocc.net/assets/wimages/pc_images/icon-warning.png" alt="">
                    <p style="font-size: 24px;padding: 20px 0;">请下载Flash插件或切换至HTML5播放器观看</p>
                    <a href="https://www.flash.cn" target="_blank" class="install-flash-detail btn-player"
                        style="background: #55b559;">下载flash插件</a>
                    <span class="html-content">
                        <span class="or-content">或</span>
                        <span class="btn-player btn-html" @click="live_player_render(false)" style="background: #249ef3;">使用HTML5播放器</span>
                    </span>
                </div>
                <div v-else class="player-switch changeflash" @click="toChangePlayer">
                    <p class="player-content">切换{{current_player_type=='h5'?'FLASH':'HTML5'}}模式</p>
                </div>
              </div> -->
              <div class="player-container-id_wrap" v-show="sec.section_status== 2">
                <video
                  id="player-container-id" 
                  width="100%" 
                  preload="auto" 
                  playsinline 
                  webkit-playsinline>
                </video>
              </div>
              <div v-show="sec.section_status != 2" class="bg_tip">
                <img class="type_img" src="@/assets/course/live_bg.png" />
                <div class="bg_tips_status">{{ bg_tip_status2_text }}</div>
              </div>
            </div>
          </div>
          <div
            v-if="left_show_type && right_show_type"
            class="drag-wrap"
            id="webide-drag"
          >
            <span class="drag"></span>
          </div>
        </div>

        <div
          v-if="right_show_type"
          :class="['col-right', left_show_type ? '' : 'w100']"
          id="col-right"
        >
          <template v-if="right_show_type == 'intro'">
            <div
              v-if="intro_img.length || intro_teacher.length"
              class="cont cont_intro"
            >
              <div class="cont_title">
                课程介绍
                <span
                  v-if="left_show_type && right_show_type"
                  @click="handleRightHide"
                  class="right_close"
                  ><img src="@/assets/course/right_close.png" alt=""
                /></span>
              </div>
              <div class="intro_pop_con">
                <div v-if="intro_img.length" class="ct ct1">
                  <!-- <div class="ct_tit">详情</div> -->
                  <div class="ct_con ct_con1">
                    <div class="">
                      <template v-for="(item, index) in intro_img">
                        <img
                          :key="index"
                          v-if="item && item.src"
                          :src="item.src"
                          alt=""
                        />
                      </template>
                    </div>
                  </div>
                </div>
                <div v-if="intro_teacher.length" class="ct ct3">
                  <!-- <div class="ct_tit">老师</div> -->
                  <div class="ct_con">
                    <div
                      v-for="(item, index) in intro_teacher"
                      :key="index"
                      onerror="this.src='https://success-image.successkaoyan.com/userlogo/default/default01.png'"
                      class="teacher"
                    >
                      <div class="t_t">
                        <img :src="item.teacher_avatar" alt="" />
                        <div>
                          <div class="t_name">{{ item.teacher_name }}</div>
                          <div class="t_tag">{{ item.teacher_title }}</div>
                          <div class="t_b taj">{{ item.teacher_desc }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div v-if="right_show_type == 'chat'" class="cont cont_chat">
            <div class="cont_title">互动</div>
            <div class="look_count">
              直播间人气：<b class="hot_count">{{ hot_count<1?1:hot_count}}</b>
            </div>
            <div class="chatList" ref="chatList">
              <div v-for="(item, index) in chat_list" :key="index">
                <template v-if="item.msg_type == 'TIMCustomElem'">
                  <div v-if="item.type == 3001" class="chat_b fl">
                    <div class="joinRoom" v-if="item.pname==user.pname">聊天室加入成功！</div>
                    <div class="joinRoom" v-else>{{ item.pname }} 进入直播课堂</div>
                  </div>
                  <div v-if="item.type == 3002" class="chat_b">
                    <div class="joinRoom">{{ item.pname }} 离开直播课堂</div>
                  </div>
                  <template v-if="item.type == 3003">
                    <div v-if="item.flow == 'out'" class="chat_b fr">
                      <div class="chat_msg">
                        <div class="name">{{ item.pname }}</div>
                        <div class="msg taj">
                          <img class="custom_img" :src="item.img_url" />
                        </div>
                      </div>
                      <div class="avatar">
                        <img :src="item.userlogo" alt="" />
                      </div>
                    </div>
                    <div v-else class="chat_b fl">
                      <div class="avatar">
                        <img :src="item.userlogo" alt="" />
                      </div>
                      <div class="chat_msg">
                        <div class="name">{{ item.pname }}</div>
                        <div class="msg taj">
                          <img class="custom_img" :src="item.img_url" />
                        </div>
                      </div>
                    </div>
                  </template>
                  <div v-if="item.type == 3004" class="chat_b">
                    <div class="joinRoom tac">直播间已禁言</div>
                  </div>
                  <div v-if="item.type == 3005" class="chat_b">
                    <div class="joinRoom tac">直播间已解除禁言</div>
                  </div>
                  <div v-if="item.type == 3006" class="chat_b">
                    <div class="joinRoom tac">您已被禁言</div>
                  </div>
                  <div v-if="item.type == 3007" class="chat_b">
                    <div class="joinRoom tac">您已解除禁言</div>
                  </div>
                  <div v-if="item.type == 3008" class="chat_b">
                    <div class="joinRoom tac">您已被踢出</div>
                  </div>
                  <div v-if="item.type == 3009" class="flowerMsg">
                    <div class="flower_b">
                      <div class="msg">{{ item.pname }}送给老师</div>
                      <img
                        class="flower_img"
                        src="@/assets/course/flower_msg.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <template v-if="item.type == 3011">
                    <div class="systemTip taj">
                      <div class="taj">{{ item.content }}</div>
                    </div>
                  </template>
                </template>
                <template v-if="item.msg_type == 'TIMTextElem'">
                  <div v-if="item.flow == 'out'" class="chat_b fr">
                    <div class="chat_msg">
                      <div class="name">{{ item.pname }}</div>
                      <div class="msg taj" v-html="item.text"></div>
                    </div>
                    <div class="avatar">
                      <img :src="item.userlogo" alt="" />
                    </div>
                  </div>
                  <div v-else class="chat_b fl">
                    <div class="avatar">
                      <img :src="item.userlogo" alt="" />
                    </div>
                    <div class="chat_msg">
                      <div class="name">{{ item.pname }}</div>
                      <div class="msg taj" v-html="item.text"></div>
                    </div>
                  </div>
                </template>
                <template v-if="item.msg_type == 'system_tips'">
                  <div class="systemTip taj">
                    <div class="taj">{{ item.system_tips }}</div>
                  </div>
                </template>
                <template v-if="item.msg_type == 'welcome'">
                  <div class="chat_b fl">
                    <div class="joinRoom">欢迎加入社科赛斯考研直播间！</div>
                  </div>
                </template>
                <template v-if="item.msg_type == 'connecting'">
                  <div class="chat_b fl">
                    <div class="joinRoom">重新连接中。。。</div>
                  </div>
                </template>
                <template v-if="item.msg_type == 'joinerror'">
                  <div class="chat_b fl">
                    <div class="joinRoom">加入聊天室失败 <a @click="handleAgainConnect" class="againconnect" href="javascript:;">点击重连</a></div>
                  </div>
                </template>
              </div>
            </div>
            <div class="footer">
              <div v-if="emoji_pannel_ishow" class="emotions_box">
                <div class="emotions_b_top">
                  <div v-if="emoji_pannel_index == 0" class="emotions_b">
                    <template
                      v-for="(value, key, index) in emotionDataIndexs_data"
                    >
                      <img
                        :key="index"
                        :src="value"
                        alt=""
                        :id="key"
                        @click="handleClickEmotion(key)"
                      />
                    </template>
                  </div>
                  <div v-if="emoji_pannel_index == 1" class="suctom_b">
                    <template
                      v-for="(value, key, index) in custom_emotions_data"
                    >
                      <img
                        :key="index"
                        :src="value"
                        alt=""
                        :id="key"
                        @click="handleClickGif(key)"
                      />
                    </template>
                  </div>
                </div>
                <div class="emotions_b_bot">
                  <div
                    :class="['emoji_btn', emoji_pannel_index == 0 ? 'on' : '']"
                    @click="emoji_pannel_index = 0"
                  >
                    <img src="@/assets/course/exprion.png" alt="" />
                  </div>
                  <div
                    :class="['custom_btn', emoji_pannel_index == 1 ? 'on' : '']"
                    @click="emoji_pannel_index = 1"
                  >
                    <img src="@/assets/course/gif.png" alt="" />
                  </div>
                </div>
              </div>
              <template v-if="chatroom_speak && user_power.is_speak">
                <div class="emotion_wrap" @click="handleChangeEmojiPannel">
                  <img src="@/assets/course/emotion_wrap.png" alt="" />
                </div>
                <div class="chatInput">
                  <div class="send_b">
                    <!-- <div class="input_wrap"> -->
                    <textarea
                      class="input_wrap"
                      id="send_msg_text"
                      type="text"
                      placeholder="说点什么吧"
                      contenteditable="true"
                      v-model="send_msg_text"
                      @keyup="onSendMsgKeyUp"
                      maxlength="40"
                    ></textarea>
                    <!-- </div> -->
                    <!-- <div class="send_btn">发送</div> -->
                  </div>
                  <div
                    :class="['send_btn', handleTrim(send_msg_text) ? 'on' : '']"
                    @click="onSendMsg"
                  >
                    发送
                  </div>

                  <!-- 点赞 -->
                  <!-- <div class="support"><img src="/static/live/images/support.png" alt=""></div> -->
                  <!-- 未登录遮罩 -->
                  <!-- <div class="nologin footer_nologin hide">登录即可畅聊</div>
                <div class="nospeak hide">已禁言</div> -->
                </div>
              </template>
              <template v-else
                ><div class="nospeak">
                  {{ chatroom_speak == 0 ? "全体已禁言" : "您已被禁言" }}
                </div></template
              >

              <div v-if="chatroom_gift && user_power.is_gift" class="flower">
                <img
                  v-if="flower_time == 0"
                  @click="handleClickFlower"
                  class="img"
                  src="@/assets/course/flower.png"
                  alt=""
                />
                <div v-else class="clock_time">{{ flower_time }}</div>
              </div>
            </div>
          </div>
          <template v-if="right_show_type == 'qq'">
            <div class="cont cont_wx">
              <div class="cont_title">
                {{
                  right_show_sec.section_name
                    ? right_show_sec.section_name
                    : "QQ群"
                }}
                <span
                  v-if="left_show_type && right_show_type"
                  @click="handleRightHide"
                  class="right_close"
                  ><img src="@/assets/course/right_close.png" alt=""
                /></span>
              </div>
              <div class="wx_content">
                <img src="" alt="" />
                <p></p>
              </div>
            </div>
          </template>
          <template v-if="right_show_type == 'wx'">
            <div class="cont cont_wx">
              <div class="cont_title">
                {{
                  right_show_sec.section_name
                    ? right_show_sec.section_name
                    : "二维码"
                }}
                <span
                  v-if="left_show_type && right_show_type"
                  @click="handleRightHide"
                  class="right_close"
                  ><img src="@/assets/course/right_close.png" alt=""
                /></span>
              </div>
              <div class="wx_content">
                <img
                  src="https://www.successkaoyan.com/statics/indexcs/images/skwx.png"
                  alt=""
                />
                <p>{{ right_show_sec.section_wx_name }}</p>
              </div>
            </div>
          </template>
          <template v-if="right_show_type == 'pdf'">
            <div class="cont cont_pdf">
              <div class="cont_title">
                {{
                  right_show_sec.section_name
                    ? right_show_sec.section_name
                    : "讲义"
                }}
                <span
                  v-if="left_show_type && right_show_type"
                  @click="handleRightHide"
                  class="right_close"
                  ><img src="@/assets/course/right_close.png" alt=""
                /></span>
              </div>
              <div class="pdf_content">
                <!-- <pdf
                  ref="pdf"
                  v-for="i in numPages"
                  :key="i"
                  :page="i"
                  :src="pdf_url"
                >
                </pdf> -->
                <iframe style="width: 100%;height: 100%;" :src="pdf_url + '?#toolbar=0&navpanes=0&scrollbar=0'" frameborder="0" ></iframe>
              </div>
              <div class="pdf_bot" v-if="right_show_sec.section_is_download">
                <a :href="pdf_url" target="_blank"><div class="next_section">下载或打印</div></a>
              </div>
            </div>
          </template>
          <template v-if="right_show_type == 'practive'">
            <div class="cont cont_wx">
              <div class="cont_title">
                {{
                  right_show_sec.section_name
                    ? right_show_sec.section_name
                    : "练习"
                }}
                <span
                  v-if="left_show_type && right_show_type"
                  @click="handleRightHide"
                  class="right_close"
                  ><img src="@/assets/course/right_close.png" alt=""
                /></span>
              </div>
              <div class="wx_content"></div>
            </div>
          </template>
          <template v-if="right_show_type == 'homework'">
            <div v-if="homework_show_status==1" class="cont cont_homework">
              <div class="cont_title">
                {{
                  right_show_sec.section_name
                    ? right_show_sec.section_name
                    : "作业"
                }}
                <span
                  v-if="left_show_type && right_show_type"
                  @click="handleRightHide"
                  class="right_close"
                  ><img src="@/assets/course/right_close.png" alt=""
                /></span>
              </div>
              <div :class="['homework_content', left_show_type ? '' : 'w1200 ma0']">
                <div class="homework_con">
                  <div class="homework_question">
                    <div class="homework_tit">作业</div>
                    <div class="homwwork_content" v-html="homework_detail.task.task_content"></div>
                  </div>
                  <div class="homework_answer">
                    <div class="homework_tit">答案</div>
                    <div v-if="homework_detail.task.is_show_finished&&homework_detail.record" class="homwwork_content" v-html="homework_detail.task.task_content"></div>
                    <div v-else-if="homework_detail.task.is_show_finished==0" class="homwwork_content" v-html="homework_detail.task.task_content"></div>
                    <div v-else class="homwwork_content">提交作业后查看答案 拷贝</div>
                  </div>
                  <div class="homework_useranswer">
                    <div class="homework_tit">我的作业 <span v-if="homework_detail.record">{{homework_detail.record.create_time}}</span></div>
                    <div v-if="homework_detail.record" class="homwwork_content is_over">
                      <div v-for="(item,index) in homework_detail.record.task_content" :key="index" class="img_homework">
                        <el-image style="width: 100%; height: 100%" :src="item.src" :preview-src-list="getImageList(homework_detail.record.task_content)"></el-image>
                      </div>
                    </div>
                    <div v-else class="homwwork_content">未提交</div>
                  </div>
                  <div v-if="homework_detail.task.task_num" class="homework_task_num" @click="handleHomeworkStatus(3)">
                    <div class="homework_tit">{{homework_detail.task.task_num}}人已提交 点击查看</div>
                  </div>
                </div>
              </div>
              <div class="homework_bot">
                <div class="submit" @click="handleHomeworkStatus(2)">{{homework_detail.record?'已提交':'提交作业'}}</div>
                <div class="next_section" @click="handleHomeworkNext">下一节</div>
              </div>
            </div>
            <div v-else-if="homework_show_status==2" class="cont cont_homework" ref="Submit_job">
              <div class="cont_title">
                上传作业
                <span
                  @click="handleHomeworkStatus(1)"
                  class="right_close"
                  ><img src="@/assets/course/right_close.png" alt=""
                /></span>
              </div>
              <div :class="['homework_content upload_homework_content', left_show_type ? '' : 'w1200 ma0']">
                <div class="homework_con">
                  <div class="upload_homework_wrap">
                    <div class="homework_cont">
                      <div class="add_btn">
                        <div class="up_wrap">
                          <template v-for="(item, index) in homework_img">
                            <div :key="index" class="img_box" v-if="index<4">
                              <el-image style="width: 100%; height: 100%" fit="cover" :src="item.src"></el-image>
                              <div class="close" @click="closeImg(index)">
                                <i class="el-icon-close" style="color:#e2777a;"></i>
                              </div>
                            </div>
                          </template>
                          
                          <div
                            v-if="homework_img.length < 4"
                            class="upload_btn"
                            @click="uploadUserLogo()"
                          >
                            <img src="@/assets/homework/add_btn.png" alt="">
                          </div>
                        </div>
                      </div>
                      <p class="tip">仅支持图片上传，至少1张，最多4张 </p>
                      <div class="btn_sub ma0" @click="handleSubmitHomework">提交</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="homework_show_status==3" class="cont cont_homework">
              <div class="cont_title">
                {{
                  right_show_sec.section_name
                    ? right_show_sec.section_name
                    : "作业"
                }}
                <span
                  @click="handleHomeworkStatus(1)"
                  class="right_close"
                  ><img src="@/assets/course/right_close.png" alt=""
                /></span>
              </div>
              <div :class="['homework_content', left_show_type ? '' : 'w1200 ma0']" ref="myScrollbar"  id="resultScroll">
                <template v-if="homework_list.length">
                  <div class="homework_con">
                    <div v-for="(item,index) in homework_list" :key="index" class="homework_useranswer">
                      <div class="homework_tit">{{item.pname}} <span>{{item.create_time}}</span></div>
                      <div class="homwwork_content is_over">
                        <div v-for="(items,indexs) in item.task_content" :key="indexs" class="img_homework">
                          <el-image style="width: 100%; height: 100%" :src="items.src" :preview-src-list="getImageList(item.task_content)"></el-image>
                        </div>
                        <template v-if="item.task_status">
                          <img class="level_" :src="getLevelUrl(item.correct)" alt="" />
                        </template>
                      </div>
                      <div v-if="item.task_status" class="teacher_">
                        <div class="teacher_wrap">
                          <div class="name">{{item.admin_name}}</div>
                          <div class="time">{{item.correct_time}}</div>
                        </div>
                        <div class="dec_text">{{item.comment}}</div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="nocontent">
                    <img src="@/assets/common/nocontent.png" alt="" />
                    <p>暂无内容</p>
                  </div>
                </template>
              </div>
              <div class="homework_bot">
                <div class="submit" @click="handleHomeworkStatus(2)">{{homework_detail.record?'已提交':'提交作业'}}</div>
                <div class="next_section" @click="handleHomeworkNext">下一节</div>
              </div>
            </div>
          </template>
          <template v-if="right_show_type == 'notice'">
            <div class="cont cont_notice">
              <div class="cont_title">
                公告
                <span
                  v-if="left_show_type && right_show_type"
                  @click="handleRightHide"
                  class="right_close"
                  ><img src="@/assets/course/right_close.png" alt=""
                /></span>
              </div>
              <div class="notice_content">
                <template v-if="live_notice.length">
                  <template v-for="(item, index) in live_notice">
                    <div :key="index" v-if="item.notice" class="notice_b">
                      <div class="notice_sender taj">
                        <img :src="item.admin_img" alt="" />
                        <div class="sender_msg">
                          <p class="name">{{ item.admin_name }}</p>
                          <p class="send_time">{{ item.create_time }}</p>
                        </div>
                      </div>
                      <div class="send_msg">
                        <pre class="taj">{{ item.notice }}</pre>
                      </div>
                      <div v-if="item.is_need_confirm == 1" class="notice_btn">
                        <div
                          v-if="item.user_confirm == 1"
                          class="signed_notice ma0"
                        >
                          已确认收到
                        </div>
                        <div
                          v-else
                          class="sign_notice ma0"
                          @click="handleClickNotice(item.id)"
                        >
                          确认收到
                        </div>
                      </div>
                      <div
                        v-if="
                          item.is_need_confirm == 1 &&
                          item.confirm_num &&
                          item.confirm_num != 0
                        "
                        class="sign_person"
                      >
                        <div class="alreadly">
                          <p class="person_count">
                            已确认（{{ item.confirm_num }}人）
                          </p>
                          <div class="person_name"></div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="nocontent">
                    <img src="@/assets/common/nocontent.png" alt="" />
                    <p>暂无内容</p>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-if="live_ending" class="marking marking_lived">
      <div class="mark">
        <p class="mark_tit">直播已结束</p>
        <p class="mark_sec_name">{{ sec.section_name }}</p>
        <p class="mark_sec_teacher"></p>
        <p class="outBtn ma0" @click="handleToInfo">确定退出</p>
      </div>
    </div>
    <div v-if="!user_power.is_enter" class="marking marking_kick">
      <div class="mark">
        <p class="mark_tit">通知</p>
        <p class="mark_sec_teacher">
          由于您的不正当操作或言论，您已被踢出直播间。详细信息请咨询客服
        </p>
        <p class="outBtn ma0" @click="handleToInfo">确定</p>
      </div>
    </div>


    <el-dialog title="社科赛斯提醒您" :visible.sync="DialogVisible" width="300px" :show-close="false" center custom-class="login_out_dialog">
      <span>确定要退出吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleLoginOut">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TIM from "tim-js-sdk";
import { IsPC, flashChecker } from "@/assets/common/js/method.js";
import { clearCookie, getCookie,cookieGO} from "@/config/cookie.js";
import { mapActions } from "vuex";
import api from "@/config/api";
import pdf from "vue-pdf";
let Base64 = require("js-base64").Base64;
var course_data = {}; // 课程总数据
var player = null; // 视频
var live_player = null;
var live_player_flag = true;
var client = 4;
var groupId = null;
let chat_is_join = false
var base_count = 0;
var add_count = 0;
var reduce_count = 0;
var timer = null;
var time = 0;

let studyCookie_timer= null;

var flower_timer = null;

var live_study_time = 0;
var live_study_timer = null;

// 发消息间隔2秒
let sendMsg_flag = true;
// 重新连接间隔
let againconnect_flag = true;

let emotionDataIndexs = [];
let custom_emotions = [];

let real_name = '';

let section_pull_code_player = ''

function parseText(val) {
  //解析文本和表情
  var expr = /\[[^[\]]{1,5}\]/g;

  var valHtml = "";
  var msg_emotions = val.match(expr);
  var tmsg, emotionIndex, restMsgIndex;

  if (!msg_emotions || msg_emotions.length < 1) {
    // 没有表情
    valHtml += val;
  } else {
    //有表情
    var tmsg = "";
    for (var i = 0; i < msg_emotions.length; i++) {
      tmsg = val.substring(0, val.indexOf(msg_emotions[i]));
      if (tmsg) {
        valHtml += tmsg;
      }
      emotionIndex = emotionDataIndexs[msg_emotions[i]] + "";
      // emotion_arr = emotions[emotionIndex]
      if (emotionIndex == "undefined") {
        valHtml += msg_emotions[i];
      } else {
        if (emotionIndex) {
          valHtml += '<img class="face_img" src="' + emotionIndex + '"/>';
        } else {
          valHtml += tmsg;
        }
      }

      restMsgIndex = val.indexOf(msg_emotions[i]) + msg_emotions[i].length;
      val = val.substring(restMsgIndex);
    }
    if (val) {
      valHtml += val;
    }
  }
  return valHtml;
}
// cos 作业上传用
import { randomWord } from "@/assets/common/js/method.js";
import {host} from "@/config/config.js";
var COS = require("cos-js-sdk-v5");
var Bucket = "successkaoyan-1254014383";
var Region = "ap-beijing"; /* 存储桶所在地域，必须字段 */

// 初始化实例
var cos = new COS({
  Domain: "success-image.successkaoyan.com",
  Protocol: "https:",
  getAuthorization: function (options, callback) {
    var url = host + "cos_s";
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.onload = function (e) {
      try {
        var data = JSON.parse(e.target.responseText);
      } catch (e) {}
      callback({
        TmpSecretId: data.credentials && data.credentials.tmpSecretId,
        TmpSecretKey: data.credentials && data.credentials.tmpSecretKey,
        XCosSecurityToken: data.credentials && data.credentials.sessionToken,
        ExpiredTime: data.expiredTime,
      });
    };
    xhr.send();
  },
});
//获取年月日
var dateimg = new Date();
var yearimg = dateimg.getFullYear() + "";
var monthimg = dateimg.getMonth() + 1 + "";
var dayimg = dateimg.getDate() + "";
var simg = dateimg.getTime() + "";
if (monthimg < 10) {
  monthimg = "0" + monthimg;
}

if (dayimg < 10) {
  dayimg = "0" + dayimg;
}
var timeimg = yearimg + monthimg + dayimg;

import { md5 } from "../config/md5";

let socket_switch = 0;
const socket_num = 30;
let socket_connect_num = 0;
let socket_error_flag = true;
let socket_close_flag = true;

// 登录im最大次数
let login_im_maxnum = 3;
let login_im_num = 0;

export default {
  components: {
    // pdf,
  },
  data() {
    return {
      logined: false,
      user: {},
      time_table_current_id: 1,
      time_table_tab: [
        {
          url: require("@/assets/watch/timeable.png"),
          url_on: require("@/assets/watch/timeable_on.png"),
          title: "课表",
          is_show: true,
          id: 1,
        },
        {
          url: require("@/assets/watch/intro.png"),
          url_on: require("@/assets/watch/intro_on.png"),
          title: "介绍",
          is_show: false,
          id: 2,
        },
        {
          url: require("@/assets/watch/practive.png"),
          url_on: require("@/assets/watch/practive_on.png"),
          title: "练习",
          is_show: false,
          id: 3,
        },
        {
          url: require("@/assets/watch/homework.png"),
          url_on: require("@/assets/watch/homework_on.png"),
          title: "作业",
          is_show: false,
          id: 4,
        },
        {
          url: require("@/assets/watch/pdf.png"),
          url_on: require("@/assets/watch/pdf_on.png"),
          title: "讲义",
          is_show: false,
          id: 5,
        },
        {
          url: require("@/assets/watch/notice.png"),
          url_on: require("@/assets/watch/notice_on.png"),
          title: "公告",
          is_show: false,
          id: 6,
        },
        // {
        //   url: require("@/assets/watch/download.png"),
        //   title: "下载",
        //   is_show: true,
        // },
      ],
      course: {},

      time_table: [],
      time_table_practive: [],
      time_table_homework: [],
      time_table_pdf: [],

      vid: "",
      ts: "",
      sign: "",

      sec: {},
      course_id: null,
      chapter_id: null,
      sec_id: null,
      sec_name: "",
      sec_type: "",
      prev_time: null,
      start_time: null,
      section_audition: 0,

      recently_chapter_id: null,
      recently_sec_id: null,

      bg_tip_status: "player", // 控制头部显示【0解锁、1视频节、2直播提示、3讲义提示、4群提示、6练习、7作业、player播放器】
      bg_tip_status2_text: "", // 直播提示文案

      pdf_url: "",
      numPages: "", // pdf 总页数

      is_click_sec: false,
      is_playing: false,
      live_is_playing:false,

      time_table_isshow: false,

      left_show_type: "", // 左侧显示内容类型
      right_show_type: "", // 右侧显示内容类型
      right_show_sec: {}, // 右侧显示 节数据

      hot_count: 0, // 直播间人气
      chat_list: [], // 聊天室数据
      send_msg_text: "", // 输入消息input
      emoji_pannel_ishow: false, // 表情面板是否显示
      emoji_pannel_index: 0, // 表情面板显示下表
      emotionDataIndexs_data: [],
      custom_emotions_data: [],
      flower_time: 0, // 送花倒计时

      intro_img: [], // 介绍 图 （包括公共图）
      intro_teacher: [], // 介绍 老师

      live_notice: [], // 直播公告

      chatroom_gift: 1, // 聊天室权限 是否可以送花 1可以
      chatroom_speak: 1, // 聊天室权限 是否可以聊天 1可以
      user_power: {
        is_enter: 1,
        is_fav: 1,
        is_gift: 1,
        is_speak: 1,
      },

      notice_before: false, // 公告小红点
      live_ending: false, // 自定义消息 直播结束

      homework_detail: {
        task:{}
      },
      homework_show_status: 1, // 1 作业详情 2 上传作业  3 作业列表
      homework_img:[],
      homework_list:[],
      homework_page:1,
      homework_limit:5,

      DialogVisible: false, // 退出确认框
      flash_tips_show: true, // 未下载flash 提示
      current_player_type: 'h5', // 当前使用播放器方式 h5/flash

      socketio: '',
      timeoutObj: undefined,
      serverTimeoutObj: undefined,
      lockReconnect: false, // 为true时，是心跳重连的websocket断开连接
      transaction_number: '',
    };
  },
  created() {
    this.$emit("public_header", false);
  },
  mounted() {
    let _this = this
    let { name, params } = this.$route;
    this.course_id = params.course_id;

    let islogin = this.islogin();
    if(islogin){
      this.logined = true;
      this.user = this.$store.state.user;

      let onMessageReceived = function (event) {
        _this.renderMsg(event.data[0]);
      };
      this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);

      // let onSdkReady = function(){
      //   console.log(groupId)
      //   if(groupId){
      //     _this.joinGroup()
      //   }
      // }
      // this.tim.on(TIM.EVENT.SDK_READY, onSdkReady); 

      let param = {
        userID: this.user.chat_only_code,
        userSig: this.user.chat_sign,
      }
      this.handleLoginIm(param)
        
        let onKickedOut = function (event) {
            _this.$message({
              showClose: true,
              message:  _this.kickedOutReason(event.data.type),
              type: "warning",
            });
            _this.$router.push(`/course_info/${ _this.course_id }`);
          // TIM.TYPES.KICKED_OUT_MULT_ACCOUNT(Web端，同一账号，多页面登录被踢)
          // TIM.TYPES.KICKED_OUT_MULT_DEVICE(同一账号，多端登录被踢)
          // TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED(签名过期。使用前需要将SDK版本升级至v2.4.0或以上)
        };
        this.tim.on(TIM.EVENT.KICKED_OUT, onKickedOut);
      // this.tim.on(TIM.EVENT.NET_STATE_CHANGE, function (event) {
      //   // 网络状态发生改变（v2.5.0 起支持）。
      //   // event.name - TIM.EVENT.NET_STATE_CHANGE
      //   // event.data.state 当前网络状态，枚举值及说明如下：
      //   //   - TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
      //   //   - TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
      //   //   - TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
      //   if (event.data.state == "connecting") {
      //     tips("当前网络不稳定");
      //   } else if (event.data.state == "disconnected") {
      //     tips("未接入网络");
      //     $(".bg_img img").attr("src", "/static/live/images/no_net.png");
      //   }
      // });

      let { emotionDataIndexs_data, custom_emotions_data } = this.$store.state;
      emotionDataIndexs = emotionDataIndexs_data;
      this.emotionDataIndexs_data = emotionDataIndexs_data;
      custom_emotions = custom_emotions_data;
      this.custom_emotions_data = custom_emotions_data;

      this.getData(params);
    }else{
      this.logined = false;
      this.user = {};
      this.$router.push(`/login?re_url=${this.$route.path}`);
    }
    
  },
  beforeCreate() {},
  befopxount() {},
  beforeUpdate() {},
  updated() {},
 async beforeDestroy() {
    this.$emit("public_header", true);
    let islogin = await this.islogin();
    if(islogin){
      this.destroyPlayer();
      this.destroyLivePlayer();

      this.changePersonCount(2);
    }
    
    clearInterval(timer);
    clearInterval(flower_timer);
    clearInterval(studyCookie_timer)
    // this.tim.logout();
    clearInterval(live_study_timer)

    let onMessageReceived = function(event) {
      // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
      // event.name - TIM.EVENT.MESSAGE_RECEIVED
      // event.data - 存储 Message 对象的数组 - [Message]
    };
    this.tim.off(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
  },
  destroyed() {
    this.websocketClear()
  },
  activated() {

  },
  methods: {
    ...mapActions(["setUserAndState"]),
    handleLoginIm(param){
      let _this= this
      login_im_num+=1;
      this.tim
        .login(param)
        .then(function (imResponse) {
          // console.log(imResponse.data); // 登录成功
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
            // console.log(imResponse.data.errorInfo);
          }
        })
        .catch(function (imError) {
          if(login_im_num<login_im_maxnum){
            _this.handleResetIm()
          }
          console.warn(imError); // 登录失败的相关信息
        });
    },
    async handleResetIm(){
      let res = await api.reset_im({});
        if (res.code == 0) {
          this.handleLoginIm({
            userID: res.result.c,
            userSig: res.result.s,
          })

          let old_userInfo = this.$store.state.user;
          if(res.result&&res.result.c){
            old_userInfo.chat_only_code= res.result.c
          }
          if(res.result&&res.result.s){
            old_userInfo.chat_sign= res.result.s
          }
          cookieGO("userInfo", JSON.stringify(old_userInfo));
          this.setUserAndState(old_userInfo);
          this.user = old_userInfo;
        }
    },
    initWebSocket(msg){
      let _this = this
      let protocol = 'ws'
     if (location.protocol === 'https:') {
       protocol = 'wss'
     }
     // 项目部署到线上 同域名
     const herf = location.host
    //  var url = protocol + '://' + herf + `/websocket/${this.$route.query.chatRoom ? this.$route.query.chatRoom : this.$route.query.chatroom}/${this.user.id}/${encodeURI(this.user.name)}`
    // var url = protocol+'://socket.successkaoyan.com:80'
    var url = protocol+'://socket.successkaoyan.com:7092'
    // var url = protocol+'://82.156.28.13:7092/im/send'
    // var url = protocol+'://192.168.31.187:7091/im/send'
    // var url = 'ws://121.40.165.18:8800'
     this.socketio = new WebSocket(url)
     // 建立连接
     this.socketio.onopen = function () {
       socket_error_flag = true;
       socket_close_flag = true;
       console.log('已经连通了')
       if(msg){
        _this.socketio.send(msg)
       }
       _this.longstart();   // 成功建立连接后，创建心跳检测
     }
     // 接收消息
     // 接收后台服务端的消息    接收到消息
     this.socketio.onmessage = (evt) => {
       console.log('接受消息')
      _this.longstart(); 
       try {
           var data = JSON.parse(evt.data)
          //  console.log(data)
       } catch(err){
           return
       }
     }
     // 连接建立失败重连
     this.socketio.onerror = this.websocketonerror
     // 关闭
     this.socketio.onclose = this.websocketclose
    },
    socketiosend(msg){
      if(!this.socketio){
        return
      }
      if(this.socketio.readyState == WebSocket.OPEN){
        // console.log(msg)
        this.socketio.send(msg)
      }else{
        // console.log('websocket连接未开启')
        if(socket_connect_num<socket_num){
          this.initWebSocket(msg)
            socket_connect_num+=1;
        }
      }
    },
    websocketonerror (error) { // 连接建立失败重连
     console.log('websocket连接断开')
     if(socket_error_flag){
      this.handleSendError('error',error)
      socket_error_flag = false;
     }
     
     if(socket_connect_num<socket_num){
       setTimeout(()=>{
        this.initWebSocket()
        socket_connect_num+=1;
       },2000)
     }
    },
    websocketclose (error) { // 关闭
      if(socket_close_flag){
        this.handleSendError('close',error)
        socket_close_flag = false;
      }
      
     console.log('断开连接')
     if(socket_connect_num<socket_num){
      setTimeout(()=>{
        if(this.lockReconnect){
          this.initWebSocket()
        }
      },2000)
     }
    },
    websocketClear(){
      this.lockReconnect=false
      // 1、组件销毁时，关闭与服务器的连接
      if (this.socketio) {
       this.socketio.close() // 离开路由之后断开websocket连接
      }
      // 2、通过关闭计时器和倒计时关闭心跳监测
      clearInterval(this.timeoutObj)
      clearTimeout(this.serverTimeoutObj);
    },
    longstart(){
      //1、通过关闭定时器和倒计时进行重置心跳
      clearInterval(this.timeoutObj)
      clearTimeout(this.serverTimeoutObj);
      // 2、每隔30s向后端发送一条商议好的数据
      this.timeoutObj = setInterval(()=>{
        console.log('重置监测心跳')
        let websocketsendParam = {}
        if(this.sec.section_type==1){
          let speed = player.HTML5 ? player.HTML5.video.playbackRate.toFixed(1) : 1;
          var j2s_getCurrentTime = parseInt(player.j2s_getCurrentTime());
          websocketsendParam = {
            type:1,
            data: {
              course_id: this.course_id,
              chapter_id: this.chapter_id,
              section_id: this.sec_id,
              section_name: this.sec_name,
              uid: this.user.id,
              ua: navigator.userAgent,
              timestamp: this.timestampToTime(),
              type: 3,
              speed: speed,
              player_current_time: j2s_getCurrentTime,
              client: 2,
              transaction_number: this.transaction_number
            }
          }
        }
        if(this.sec.section_type==2){
          websocketsendParam = {
              type:2,
              data: {
                  course_id: this.course_id,
                  chapter_id: this.chapter_id,
                  section_id: this.sec_id,
                  section_name: this.sec_name,
                  uid: this.user.id,
                  ua: navigator.userAgent,
                  timestamp: this.timestampToTime(),
                  type: 3,
                  client:2,
                  transaction_number:this.transaction_number,
                  in_type:-1,
                  data:''
              }
          }
        }
        // console.log(JSON.stringify(websocketsendParam))
        this.socketiosend(JSON.stringify(websocketsendParam))
        // 3、发送数据 2s后没有接收到返回的数据进行关闭websocket重连
        this.serverTimeoutObj = setTimeout(()=>{
            this.lockReconnect = true // 心跳重连设置为true
            // console.log("后台挂掉，没有心跳了....");
            this.socketio.close();
        }, 30000);
      },30000)
      if(this.sec.section_type==1){
        if(!this.is_playing){
          clearInterval(this.timeoutObj)
          clearTimeout(this.serverTimeoutObj);
        }
      }
      if(this.sec.section_type==2){
        if(!this.live_is_playing){
          clearInterval(this.timeoutObj)
          clearTimeout(this.serverTimeoutObj);
        }
      }
      
    },
    timestampToTime() {
      return parseInt(new Date().getTime()/1000)
    },
    async handleSendError(type,msg){
      // console.log(msg)
      let res = await api.socket_error({
        ua:navigator.userAgent,
        client:2,
        type:type,
        section_id:this.sec.id
      });
    },
    handleSlide(id) {
      //  time_table_tab下标  0课表 1介绍 2练习 3作业 4讲义 5公告
      this.time_table_current_id = id;
      if (id == 2) {
        this.time_table_isshow = false;
        this.right_show_type = "intro";
        this.right_show_sec = {};
      }
      if (id == 6) {
        this.time_table_isshow = false;
        this.right_show_type = "notice";
        this.right_show_sec = {};
      }
    },
    handleSlideOver(id) {
      this.time_table_current_id = id;
      if (id == 1) {
        this.time_table = this.course.chapter;
        this.time_table_isshow = true;
      } else if (id == 3) {
        this.time_table = this.time_table_practive;
        this.time_table_isshow = true;
      } else if (id == 4) {
        this.time_table = this.time_table_homework;
        this.time_table_isshow = true;
      } else if (id == 5) {
        this.time_table = this.time_table_pdf;
        this.time_table_isshow = true;
      } else {
        this.time_table_isshow = false;
        // this.right_show_type = "intro";
        // this.right_show_sec = {};
      }
    },
    handleSlideOut() {
      this.time_table_isshow = false;
    },
    async getData(options) {
      let _this = this;
      
      try {
        let res = await api.detail({
          id: this.course_id,
        });
        if (res.code == 0) {
          let {
            course_info_src,
            teacher_top_img,
            teacher_detail,
            course_common_src,
            teacher,
            chapter,
            course_is_shelf,
            course_is_expire,
            socket_switch
          } = res.result;
          socket_switch = socket_switch;
          if (course_is_shelf == 1) {
            this.$message({
              showClose: true,
              message: "该课程已下架",
              type: "warning",
            });
            setTimeout(()=>{
              window.opener=null;
              window.open('','_self');
              window.close();
            },2000)
            return;
          }
          if (course_is_expire == 1) {
            this.$message({
              showClose: true,
              message: "该课程已过期",
              type: "warning",
            });
            setTimeout(()=>{
              window.opener=null;
              window.open('','_self');
              window.close();
            },2000)
            return;
          }
          try{
            if(options.chapter_id){
              let is_look = chapter.find(item => {
                  return item.id==options.chapter_id;
              });
              if(!is_look){
                // this.$router.push(`/mine/my_course`);
                this.$message({
                  showClose: true,
                  message: "该课程暂不支持观看",
                  type: "warning",
                });
                setTimeout(()=>{
                  window.opener=null;
                  window.open('','_self');
                  window.close();
                },2000)
                // return;
              }
            }
          }catch(e){
            this.$router.push(`/mine/my_course`);
          }
          
          /* 判断是否有 详情图 老师-----------------------------start */
          let intro_img = [];
          if (course_info_src && course_info_src.length > 0) {
            intro_img = intro_img.concat(course_info_src);
          }
          if (teacher_detail && teacher_detail.length > 0) {
            intro_img = intro_img.concat(teacher_top_img);
            intro_img = intro_img.concat(teacher_detail);
          }
          if (course_common_src && course_common_src.length > 0) {
            intro_img = intro_img.concat(course_common_src);
          }
          this.intro_img = intro_img;

          if (teacher) {
            this.intro_teacher = teacher;
          }
          if (this.intro_img.length || this.intro_teacher) {
            this.time_table_tab[1].is_show = true;
          }
          /* 判断是否有 详情图 老师-----------------------------start */

          /* 课表  找出练习、作业、讲义课表数据-----------------------------start */
          let res_course = res.result;
          res_course.chapter.forEach((item) => {
            item.is_show = false;
            var sec_index = 0;
            item.section.forEach((sec, sec_ind) => {
              sec_index += 1;
              sec.sec_index = sec_index;
            });
          });

          let old_chapter = res_course.chapter
          let new_chapter = old_chapter.filter((item)=>{
            return item.section.length!=0
          })
          if(new_chapter.length==0){
             this.$message({
              showClose: true,
              message: '该课程暂无可以观看内容，请耐心等待~',
              type: "",
            });
            setTimeout(()=>{
              window.opener=null;
              window.open('','_self');
              window.close();
            },2000)
            return
          }
          res_course.chapter = new_chapter
          this.course = res_course;
          // 存储原始数据
          course_data = res_course;

          let course_ = JSON.parse(JSON.stringify(res_course));
          let course_practive_chapter = [];
          let course_homework_chapter = [];
          let course_pdf_chapter = [];
          course_.chapter.forEach((item) => {
            item.is_show = true;
            var practive_section_arr = item.section.filter((sec) => {
              return sec.section_type == 6;
            });
            var homework_section_arr = item.section.filter((sec) => {
              return sec.section_type == 7;
            });
            var pdf_section_arr = item.section.filter((sec) => {
              return sec.section_type == 3;
            });
            if (practive_section_arr.length) {
              // 如果增加字段，push需要追加
              course_practive_chapter.push({
                chapter_name: item.chapter_name,
                course_id: item.course_id,
                id: item.id,
                is_show: item.is_show,
                section: practive_section_arr,
              });
            }
            if (homework_section_arr.length) {
              course_homework_chapter.push({
                chapter_name: item.chapter_name,
                course_id: item.course_id,
                id: item.id,
                is_show: item.is_show,
                section: homework_section_arr,
              });
            }
            if (pdf_section_arr.length) {
              course_pdf_chapter.push({
                chapter_name: item.chapter_name,
                course_id: item.course_id,
                id: item.id,
                is_show: item.is_show,
                section: pdf_section_arr,
              });
            }
          });
          //  time_table_tab下标  0课表 1介绍 2练习 3作业 4讲义 5公告
          if (course_practive_chapter.length) {
            this.time_table_practive = course_practive_chapter;
            this.time_table_tab[2].is_show = true;
          }
          if (course_homework_chapter.length) {
            this.time_table_homework = course_homework_chapter;
            this.time_table_tab[3].is_show = true;
          }
          if (course_pdf_chapter.length) {
            this.time_table_pdf = course_pdf_chapter;
            this.time_table_tab[4].is_show = true;
          }
          /* 课表  找出练习、作业、讲义课表数据-----------------------------start */

          // 将最近学习加上  (判断最近学习节是否还在)
          if (res_course.last_view) {
            let recently_chapter_id = res_course.last_view.chapter_id
            let recently_sec_id = res_course.last_view.section_id;
            // let recently_chapter_id = 1
            // let recently_sec_id = 2;
            let flag = false
            course_.chapter.forEach((item,index)=>{
              if(item.id==recently_chapter_id){
                flag = item.section.find(items =>{
                    return items.id ==recently_sec_id
                });
                return flag
              }
            })
            if(flag){
              this.recently_chapter_id = recently_chapter_id;
              this.recently_sec_id = recently_sec_id;
            }
          }

          // 判断参数是否有默认节
          if (options.sec_id) {
            this.handleChangeTopSec(options.chapter_id, options.sec_id);
          } else {
            // 是否有最近学习
            if (this.recently_chapter_id) {
              this.handleChangeTopSec(
                this.recently_chapter_id,
                this.recently_sec_id
              );
            } else {
              this.handleChangeTopSec();
            }
          }

          // console.log(socket_switch)
          if(socket_switch==1){
            if (window.WebSocket) {
              this.initWebSocket()
            }else{
              this.handleSendError('no_support')
              console.log('当前浏览器不支持websocketio连接')
            }
          }

        } else if (res.code == 20003 || res.code == 20001) {
          // 课程不存在
          let islogin = await this.islogin();
          if (islogin) {
            this.$router.push(`/mine/my_course`);
          } else {
            this.$router.push(`/login?re_url=${this.$route.path}`);
          }
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: "warning",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async handleClickSection(sec) {
      try {
        let onSdkReady = function(event) {
         
        };
        this.tim.off(TIM.EVENT.SDK_READY,onSdkReady);
      } catch (error) {
        console.log(error)
      }
      if (sec.section_type == 1 || sec.section_type == 2) {
        if (player) {
          // if (this.is_playing) {
          await this.study("end");
          this.studyCookie_submit()
          this.is_click_sec = true;
          // }
        }
      }

      if (sec.section_type == 2) {
        this.live_notice = [];
        this.time_table_tab[5].is_show = false;
        if (this.right_show_type == "notice") {
          this.right_show_type = "";
        }
      }

      await this.handleChangeTopSec(sec.chapter_id, sec.id);

      // 1点播  2直播 3讲义  4QQ群 5微信  6练习 7作业
      if (sec.section_type != 1 && sec.section_type != 2) {
        await this.study();
      }

      // this.time_table_isshow = false;
    },
    handleChangeTopSec(chapter_id, sec_id) {
      // section_type 1点播  2直播 3讲义  4QQ群 5微信  6练习 7作业
      try {
        if (sec_id) {
          this.course.chapter.forEach((item) => {
            // 匹配章
            if (item.id == chapter_id) {
              this.chapter_id = chapter_id;
              item.is_show = true;
              item.section.forEach(async (sec) => {
                // 匹配节
                if (sec.id == sec_id) {
                  // 是否加入学习
                  if (this.course.is_join) {
                    if (this.course.is_course_black) {
                      // 是否需要判断黑名单---------
                      if (this.course.is_black) {
                        // 整套不能听
                        this.destroyPlayer();
                        this.bg_tip_status = 0;

                        if (sec.section_type == 1 || sec.section_type == 2) {
                          this.sec_id = sec_id;
                          this.sec_name = sec.section_name;
                          this.sec_type = sec.section_type;
                          this.prev_time = sec.prev_time;
                          this.section_audition = sec.section_audition;

                          this.sec = sec;
                        }
                      } else {
                        if (item.is_black) {
                          // 整章不能听
                          this.destroyPlayer();
                          this.bg_tip_status = 0;

                          if (sec.section_type == 1 || sec.section_type == 2) {
                            this.sec_id = sec_id;
                            this.sec_name = sec.section_name;
                            this.sec_type = sec.section_type;
                            this.prev_time = sec.prev_time;
                            this.section_audition = sec.section_audition;

                            this.sec = sec;
                          }
                        } else {
                          if (sec.is_black) {
                            // 本节不能听
                            this.destroyPlayer();
                            this.bg_tip_status = 0;

                            if (
                              sec.section_type == 1 ||
                              sec.section_type == 2
                            ) {
                              this.sec_id = sec_id;
                              this.sec_name = sec.section_name;
                              this.sec_type = sec.section_type;
                              this.prev_time = sec.prev_time;
                              this.section_audition = sec.section_audition;

                              this.sec = sec;
                            }
                          } else {
                            await this.handleSecType(sec);
                          }
                        }
                      }
                    } else {
                      await this.handleSecType(sec);
                    }
                  } else {
                    if (sec.section_type == 1 || sec.section_type == 2) {
                      if (sec.section_audition == 1) {
                        if (sec.section_is_online) {
                          if (sec.section_type == 1) {
                            this.left_show_type = "play";
                            this.bg_tip_status = "player";
                            if (player) {
                              this.changePlayerVid(sec);
                              throw Error();
                            } else {
                              this.initPlayer(sec);
                            }
                          } else if (sec.section_type == 2) {
                            // 试听  直播 、、、、、、、、、、、、、、、、、、
                            if (this.sec.section_type == 1) {
                              if (sec.section_status == 2) {
                                this.destroyPlayer();
                                this.left_show_type = "live";
                                this.right_show_type = "chat";
                                this.getLiveInfo(sec);
                              } else if (sec.section_status == 4) {
                                this.left_show_type = "play";
                                this.bg_tip_status = "player";
                                if (player) {
                                  this.changePlayerVid(sec);
                                } else {
                                  this.initPlayer(sec);
                                }
                              } else {
                                this.left_show_type = "live";
                                this.destroyPlayer();
                                if (sec.section_status == 1) {
                                  this.bg_tip_status2_text =
                                    "欢迎进入直播课堂，直播稍后开始，请不要走开";
                                } else if (sec.section_status == 3) {
                                  this.bg_tip_status2_text = "直播结束";
                                } else if (sec.section_status == 5) {
                                  this.bg_tip_status2_text = "无回放";
                                }
                              }
                            } else if (this.sec.section_type == 2) {
                              if (this.sec.section_status == 2) {
                                if (sec.section_status == 2) {
                                  this.destroyLivePlayer();
                                  this.left_show_type = "live";
                                  this.right_show_type = "chat";
                                  this.getLiveInfo(sec);
                                } else if (sec.section_status == 4) {
                                  this.destroyLivePlayer();
                                  this.left_show_type = "play";
                                  this.bg_tip_status = "player";
                                  if (player) {
                                    this.changePlayerVid(sec);
                                  } else {
                                    this.initPlayer(sec);
                                  }

                                  if (this.right_show_type == "chat") {
                                    this.right_show_type = "";
                                    this.right_show_sec = {};
                                  } else {
                                    // 不操作
                                  }
                                } else {
                                  this.left_show_type = "live";
                                  this.destroyLivePlayer();
                                  if (sec.section_status == 1) {
                                    this.bg_tip_status2_text =
                                      "欢迎进入直播课堂，直播稍后开始，请不要走开";
                                  } else if (sec.section_status == 3) {
                                    this.bg_tip_status2_text = "直播结束";
                                  } else if (sec.section_status == 5) {
                                    this.bg_tip_status2_text = "无回放";
                                  }
                                  console.log(this.right_show_type);
                                  if (this.right_show_type == "chat") {
                                    this.right_show_type = "";
                                    this.right_show_sec = {};
                                  } else {
                                    // 不操作
                                  }
                                }
                              } else if (this.sec.section_status == 4) {
                                if (sec.section_status == 2) {
                                  this.left_show_type = "live";
                                  this.right_show_type = "chat";
                                  this.getLiveInfo(sec);
                                } else if (sec.section_status == 4) {
                                  if (player) {
                                    this.changePlayerVid(sec);
                                  } else {
                                    this.initPlayer(sec);
                                  }
                                } else {
                                  this.left_show_type = "live";
                                  this.destroyPlayer();
                                  if (sec.section_status == 1) {
                                    this.bg_tip_status2_text =
                                      "欢迎进入直播课堂，直播稍后开始，请不要走开";
                                  } else if (sec.section_status == 3) {
                                    this.bg_tip_status2_text = "直播结束";
                                  } else if (sec.section_status == 5) {
                                    this.bg_tip_status2_text = "无回放";
                                  }
                                }
                              } else {
                                if (sec.section_status == 2) {
                                  this.left_show_type = "live";
                                  this.right_show_type = "chat";
                                  this.getLiveInfo(sec);
                                } else if (sec.section_status == 4) {
                                  this.left_show_type = "play";
                                  this.bg_tip_status = "player";
                                  if (player) {
                                    this.changePlayerVid(sec);
                                  } else {
                                    this.initPlayer(sec);
                                  }
                                } else {
                                  this.left_show_type = "live";
                                  if (sec.section_status == 1) {
                                    this.bg_tip_status2_text =
                                      "欢迎进入直播课堂，直播稍后开始，请不要走开";
                                  } else if (sec.section_status == 3) {
                                    this.bg_tip_status2_text = "直播结束";
                                  } else if (sec.section_status == 5) {
                                    this.bg_tip_status2_text = "无回放";
                                  }
                                }
                              }
                            } else {
                              if (sec.section_status == 2) {
                                this.left_show_type = "live";
                                this.right_show_type = "chat";
                                this.getLiveInfo(sec);
                              } else if (sec.section_status == 4) {
                                this.left_show_type = "play";
                                this.bg_tip_status = "player";
                                if (player) {
                                  this.changePlayerVid(sec);
                                } else {
                                  this.initPlayer(sec);
                                }
                              } else {
                                this.left_show_type = "live";
                                if (sec.section_status == 1) {
                                  this.bg_tip_status2_text =
                                    "欢迎进入直播课堂，直播稍后开始，请不要走开";
                                } else if (sec.section_status == 3) {
                                  this.bg_tip_status2_text = "直播结束";
                                } else if (sec.section_status == 5) {
                                  this.bg_tip_status2_text = "无回放";
                                }
                              }
                            }
                            // 试听  直播 、、、、、、、、、、、、、、、、、、
                          }
                        } else {
                          this.$notify.info({
                            title: "社科赛斯考研提醒您",
                            message: "该课程正在更新中...",
                          });
                        }
                      } else {
                        this.left_show_type="play"
                        this.destroyPlayer();
                        this.bg_tip_status = 0;
                      }
                    } else {
                      this.left_show_type="play"
                      this.destroyPlayer();
                      this.bg_tip_status = 0;
                    }

                    if (sec.section_type == 1 || sec.section_type == 2) {
                      this.sec_id = sec_id;
                      this.sec_name = sec.section_name;
                      this.sec_type = sec.section_type;
                      this.prev_time = sec.prev_time;
                      this.section_audition = sec.section_audition;

                      this.sec = sec;
                    }
                  }
                  //
                }
              });
            }
          });
        } else {
          // 默认播放某一节
          try {
            // for(var i = 0;i<this.course.chapter.length;i++){
            //   var item = this.course.chapter[i]
            //   for(var j = 0;j<item.section.length;j++){

            //   }
            // }
            this.course.chapter[0].is_show = true;
            this.handleChangeTopSec(
              this.course.chapter[0].section[0].chapter_id,
              this.course.chapter[0].section[0].id
            );
            // this.course.chapter.forEach((item) => {
            //   item.section.forEach((sec) => {
            //     // this.chapter_id = item.id;
            //     item.is_show = true;
            //     // this.sec_id = sec.id;
            //     // this.sec_name = sec.section_name;
            //     // this.sec_type = sec.section_type;
            //     // this.prev_time = sec.prev_time;
            //     // this.section_audition = sec.section_audition;
            //     // this.sec = sec;
            //     this.handleChangeTopSec(this.chapter_id, this.sec_id);
            //     throw Error();
            //   });
            // });
          } catch (e) {}
        }
      } catch (e) {}
    },
    handleSecType(sec) {
      var _this = this;
      // bg_tip_status   【0解锁、1视频节、2直播提示、3讲义提示、4群提示、6练习、7作业、player播放器】
      // 点击点播课
      if (sec.section_type == 1) {
        if (sec.section_is_online) {
          this.bg_tip_status = "player";
          if (this.left_show_type == "play") {
            if (player) {
              this.changePlayerVid(sec);
            } else {
              this.initPlayer(sec);
            }
          } else if (this.left_show_type == "live") {
            if (this.sec.section_status == 2) {
              this.destroyLivePlayer();
              if (this.right_show_type == "chat") {
                this.left_show_type = "play";
                this.bg_tip_status = "player";
                if (player) {
                  this.changePlayerVid(sec);
                } else {
                  this.initPlayer(sec);
                }
                this.right_show_type = "";
                this.right_show_sec = {};
              } else {
                this.left_show_type = "play";
                this.bg_tip_status = "player";
                if (player) {
                  this.changePlayerVid(sec);
                } else {
                  this.initPlayer(sec);
                }
              }
            } else if (this.sec.section_status == 4) {
              this.left_show_type = "play";
              this.bg_tip_status = "player";
              if (player) {
                this.changePlayerVid(sec);
              } else {
                this.initPlayer(sec);
              }
            } else {
              this.left_show_type = "play";
              this.bg_tip_status = "player";
              if (player) {
                this.changePlayerVid(sec);
              } else {
                this.initPlayer(sec);
              }
            }
          } else {
            this.left_show_type = "play";
            this.bg_tip_status = "player";
            if (player) {
              this.changePlayerVid(sec);
            } else {
              this.initPlayer(sec);
            }
          }
        } else {
          if (this.left_show_type == "live") {
            if (this.sec.section_status == 2) {
              this.destroyLivePlayer();
              this.right_show_type = "";
              this.right_show_sec = {};
            }
          }
          this.destroyPlayer();
          this.left_show_type = "play";
          this.bg_tip_status = 1;
        }
        // 点击 直播课
      } else if (sec.section_type == 2) {
        this.flash_tips_show=true // 未下载flash 提示
        this.current_player_type='h5'
        if (this.sec.section_type == 1) {
          if (sec.section_status == 2) {
            this.destroyPlayer();
            this.left_show_type = "live";
            this.right_show_type = "chat";
            this.getLiveInfo(sec);
          } else if (sec.section_status == 4) {
            this.left_show_type = "play";
            this.bg_tip_status = "player";
            if (player) {
              this.changePlayerVid(sec);
            } else {
              this.initPlayer(sec);
            }
          } else {
            this.left_show_type = "live";
            this.destroyPlayer();
            if (sec.section_status == 1) {
              this.bg_tip_status2_text =
                "欢迎进入直播课堂，直播稍后开始，请不要走开";
            } else if (sec.section_status == 3) {
              this.bg_tip_status2_text = "直播结束";
            } else if (sec.section_status == 5) {
              this.bg_tip_status2_text = "无回放";
            }
          }
        } else if (this.sec.section_type == 2) {
          if (this.sec.section_status == 2) {
            if (sec.section_status == 2) {
              this.destroyLivePlayer();
              this.left_show_type = "live";
              this.right_show_type = "chat";
              this.getLiveInfo(sec);
            } else if (sec.section_status == 4) {
              this.destroyLivePlayer();
              this.left_show_type = "play";
              this.bg_tip_status = "player";
              if (player) {
                this.changePlayerVid(sec);
              } else {
                this.initPlayer(sec);
              }

              if (this.right_show_type == "chat") {
                this.right_show_type = "";
                this.right_show_sec = {};
              } else {
                // 不操作
              }
            } else {
              this.left_show_type = "live";
              this.destroyLivePlayer();
              if (sec.section_status == 1) {
                this.bg_tip_status2_text =
                  "欢迎进入直播课堂，直播稍后开始，请不要走开";
              } else if (sec.section_status == 3) {
                this.bg_tip_status2_text = "直播结束";
              } else if (sec.section_status == 5) {
                this.bg_tip_status2_text = "无回放";
              }
              console.log(this.right_show_type);
              if (this.right_show_type == "chat") {
                this.right_show_type = "";
                this.right_show_sec = {};
              } else {
                // 不操作
              }
            }
          } else if (this.sec.section_status == 4) {
            if (sec.section_status == 2) {
              this.left_show_type = "live";
              this.right_show_type = "chat";
              this.getLiveInfo(sec);
            } else if (sec.section_status == 4) {
              if (player) {
                this.changePlayerVid(sec);
              } else {
                this.initPlayer(sec);
              }
            } else {
              this.left_show_type = "live";
              this.destroyPlayer();
              if (sec.section_status == 1) {
                this.bg_tip_status2_text =
                  "欢迎进入直播课堂，直播稍后开始，请不要走开";
              } else if (sec.section_status == 3) {
                this.bg_tip_status2_text = "直播结束";
              } else if (sec.section_status == 5) {
                this.bg_tip_status2_text = "无回放";
              }
            }
          } else {
            if (sec.section_status == 2) {
              this.left_show_type = "live";
              this.right_show_type = "chat";
              this.getLiveInfo(sec);
            } else if (sec.section_status == 4) {
              this.left_show_type = "play";
              this.bg_tip_status = "player";
              if (player) {
                this.changePlayerVid(sec);
              } else {
                this.initPlayer(sec);
              }
            } else {
              this.left_show_type = "live";
              if (sec.section_status == 1) {
                this.bg_tip_status2_text =
                  "欢迎进入直播课堂，直播稍后开始，请不要走开";
              } else if (sec.section_status == 3) {
                this.bg_tip_status2_text = "直播结束";
              } else if (sec.section_status == 5) {
                this.bg_tip_status2_text = "无回放";
              }
            }
          }
        } else {
          if (sec.section_status == 2) {
            this.left_show_type = "live";
            this.right_show_type = "chat";
            this.getLiveInfo(sec);
          } else if (sec.section_status == 4) {
            this.left_show_type = "play";
            this.bg_tip_status = "player";
            if (player) {
              this.changePlayerVid(sec);
            } else {
              this.initPlayer(sec);
            }
          } else {
            this.left_show_type = "live";
            if (sec.section_status == 1) {
              this.bg_tip_status2_text =
                "欢迎进入直播课堂，直播稍后开始，请不要走开";
            } else if (sec.section_status == 3) {
              this.bg_tip_status2_text = "直播结束";
            } else if (sec.section_status == 5) {
              this.bg_tip_status2_text = "无回放";
            }
          }
        }
      } else if (sec.section_type == 3) {
        // 讲义
        // this.destroyPlayer();
        // this.bg_tip_status = 3;
        if (sec.section_is_online) {
          this.pdf_url = sec.section_handout_url;
          // this.getNumPages();
          this.right_show_type = "pdf";
          this.right_show_sec = sec;
        }else{
          if (this.left_show_type == "live") {
            if (this.sec.section_status == 2) {
              this.destroyLivePlayer();
              this.right_show_type = "";
              this.right_show_sec = {};
            }
          }
          this.destroyPlayer();
          this.left_show_type = "play";
          this.bg_tip_status = 3;

          this.sec_id = sec.id
          this.sec_name = sec.section_name
        }
      } else if (sec.section_type == 4) {
        // qq群
        // this.destroyPlayer();
        // this.bg_tip_status = 4;
        this.right_show_type = "qq";
        this.right_show_sec = sec;
      } else if (sec.section_type == 5) {
        // wx群
        // this.destroyPlayer();
        // this.bg_tip_status = 4;
        this.right_show_type = "wx";
        this.right_show_sec = sec;
      } else if (sec.section_type == 6) {
        // 练习
        // this.destroyPlayer();
        // this.bg_tip_status = 6;
        this.right_show_type = "practive";
        this.right_show_sec = sec;
      } else if (sec.section_type == 7) {
        // 作业
        // this.destroyPlayer();
        // this.bg_tip_status = 7;
        this.right_show_type = "homework";
        this.right_show_sec = sec;

        this.getHomeWorkDetail();
      }
      // 控制头部显示【0解锁、1视频节、2直播提示、3讲义提示、4群提示、6练习、7作业、player播放器】

      if (sec.section_type == 1 || sec.section_type == 2) {
        this.sec_id = sec.id;
        this.sec_name = sec.section_name;
        this.sec_type = sec.section_type;
        this.prev_time = sec.prev_time;
        this.section_audition = sec.section_audition;

        this.sec = sec;
      }
    },
    async initPlayer(sec) {
      let res_s = await this.getSign(sec.section_vid);
      if(!res_s){
        // this.$message('视频暂未上线，请耐心等待')
        return
      }
      this.$nextTick( ()=> {
        player = polyvPlayer({
          wrap: "#player",
          width: "100%",
          height: "100%",
          vid: sec.section_vid,
          ts: res_s.ts, //web加密
          sign: res_s.sign, //web加密
          playsafe: res_s.token, //开放授权
          watchStartTime: sec.prev_time, // 从第n秒开始播放
          autoplay: true,
          hideSwitchPlayer: true,
          player_id: "bPHTRQDnPj",
          viewerInfo: {
            viewerId: this.user.id, // 观众ID
            viewerName: this.user.pname, // 观众昵称
            viewerAvatar: this.user.userlogo, // 观众头像URL
            viewerExtraInfo1: "success_" + sec.course_id, // 自定义额外信息字段1
            viewerExtraInfo2: "success_" + sec.id, // 自定义额外信息字段2
            viewerExtraInfo3: "102", // 自定义额外信息字段3
          },
          forceH5:true,
        });
        this.playerOn();
      });
    },
    async changePlayerVid(sec) {
      let res_s = await this.getSign(sec.section_vid);
      if(!res_s){
        // this.$message('视频暂未上线，请耐心等待')
        return
      }
      player.changeVid(
        JSON.stringify({
          vid: sec.section_vid,
          autoplay: true,
          ts: res_s.ts,
          sign: res_s.sign,
          playsafe: res_s.token,
          watchStartTime: sec.prev_time, // 从第n秒开始播放
          autoplay: true,
          hideSwitchPlayer: true,
          player_id: "bPHTRQDnPj",
          viewerInfo: {
            viewerId: this.user.id, // 观众ID
            viewerName: this.user.pname, // 观众昵称
            viewerAvatar: this.user.userlogo, // 观众头像URL
            viewerExtraInfo1: "success_" + sec.course_id, // 自定义额外信息字段1
            viewerExtraInfo2: "success_" + sec.id, // 自定义额外信息字段2
            viewerExtraInfo3: "102", // 自定义额外信息字段3
          },
          forceH5:true,
        })
      );
      // this.playerOn()
    },
    playerOn() {
      player.on("s2j_onPlayerInitOver", (...params) => {
        console.log("播放器数据初始化完毕：", params);
      });

      player.on("s2j_onVideoPlay", (...params) => {
        this.is_playing = true;
        console.log("视频初次播放或恢复播放时触发：", params);
        this.study("start");
        this.studyCookie()
      });
      player.on("s2j_onVideoPause", (...params) => {
        this.is_playing = false;
        console.log("视频暂停时触发：", params);
        if (!this.is_click_sec) {
          this.study("pause");
          this.studyCookie_submit()
        }
        clearInterval(this.timeoutObj)
        clearTimeout(this.serverTimeoutObj);
      });
      player.on("s2j_onPlayOver", (...params) => {
        this.is_playing = false;
        console.log("当前视频播放完毕时触发：", params);
        this.study("end");
        this.studyCookie_submit()
        // this.handleChangeTopSecNext(this.chapter_id, this.sec_id);
      });
      player.on("s2j_onVideoSeek", (...params)=>{
        console.log("视频拖拽进度时触发：", params);
        this.study('videoSeek', params)
      })
    },
    playerOn_live(){
      // 已经开始播放，调用 play() 方法或者设置了 autoplay 为 true 且生效时触发，这时 paused 属性为 false。
      live_player.on('play', (error)=> {
        console.log('已经开始播放play')
        this.live_is_playing = true
      });
      // 因缓冲而暂停或停止后恢复播放时触发，paused 属性为 false 。通常用这个事件来标记视频真正播放，play 事件只是开始播放，画面并没有开始渲染。
      live_player.on('playing', (error)=> {
        console.log('恢复播放playing')
        this.live_is_playing = true
        this.transaction_number = md5(this.sec_id+''+this.user.id+''+this.timestampToTime())
        this.websocketLiveData(1)
      });
      // 暂停时触发。
      live_player.on('pause', (error)=> {
        console.log('暂停时触发pause')
        this.live_is_playing = false
        this.websocketLiveData(2)
      });
      // 视频播放已结束时触发。此时 currentTime 值等于媒体资源最大值。
      live_player.on('ended', (error)=> {
        console.log('视频播放已结束时触发ended')
        this.live_is_playing = false;
        this.websocketLiveData(0)
      });
    },
    async websocketLiveData(type){
      let websocketsendParam = {
          type:2,
          data: {
              course_id: this.course_id,
              chapter_id: this.chapter_id,
              section_id: this.sec_id,
              section_name: this.sec_name,
              uid: this.user.id,
              ua: navigator.userAgent,
              timestamp: this.timestampToTime(),
              type: type,
              client:2,
              transaction_number:this.transaction_number,
              in_type:-1,
              data:''
          }
      }
      this.socketiosend(JSON.stringify(websocketsendParam))
      if(type==2||type==0){
        clearInterval(this.timeoutObj)
        clearTimeout(this.serverTimeoutObj);
      }
    },
    async studyCookie(){
      studyCookie_timer = setInterval(()=>{
        let studyCookie_data = getCookie('studyCookie')
        // let currentClass = this.course_id+'-'+this.chapter_id+'-'+this.sec_id
        if(studyCookie_data&&studyCookie_data!='undefined'){
          studyCookie_data= JSON.parse(studyCookie_data)
          if(studyCookie_data.length){
            studyCookie_data.forEach((item,index)=>{
              if(item.course_id==this.course_id&&item.chapter_id==this.chapter_id&&item.section_id==this.sec_id){
                item.play_minute = item.play_minute+5
              }
            })
            cookieGO('studyCookie',JSON.stringify(studyCookie_data))
          }
        }else{
          cookieGO('studyCookie',JSON.stringify(
            [{course_id:this.course_id,
            chapter_id:this.chapter_id,
            section_id:this.sec_id,
            play_minute:5}]
          ))
        }
      },5000)
    },
    async studyCookie_submit(){
      let studyCookie_data = getCookie('studyCookie')
      // console.log('studyCookie_submit',studyCookie_data)
      if(studyCookie_data&&studyCookie_data!='undefined'){
        let res = await api.study_duration({
          data:studyCookie_data
        })
        if(res.code==0){
          clearInterval(studyCookie_timer)
          clearCookie("studyCookie");
        }
      }
    },
    async study(status, live_status) {
      // console.log(status, live_status);
      var obj = null;
      if (status) {
        var realPlayStatus_str = player.j2s_realPlayStatus();
        if (realPlayStatus_str) {
          var realPlayStatus = JSON.parse(realPlayStatus_str);
          // console.log('获取实时播放状态：', realPlayStatus);
          // if (realPlayStatus.playduration > 0) {
          var video_alltime = player.j2s_getDuration();
          var j2s_getCurrentTime = parseInt(player.j2s_getCurrentTime());
          // console.log(j2s_getCurrentTime)
          // var j2s_realPlayVideoTime = player.j2s_realPlayVideoTime();
          // var is_view = 0;
          // if (j2s_getCurrentTime == video_alltime) {
          //   is_view = 1;
          // }
          let speed = player.HTML5 ? player.HTML5.video.playbackRate.toFixed(1) : 1;
          var view_status = "";
          var start_view = "";
          var end_view = "";
          var is_view = "";

          if (status == "start") {
            view_status = 1;
            start_view = j2s_getCurrentTime;
            end_view = 0;
            is_view = 0;
            this.start_time = j2s_getCurrentTime;

            this.transaction_number = md5(this.sec_id+''+this.user.id+''+this.timestampToTime())
          } else if (status == "pause") {
            view_status = 2;
            start_view = this.start_time;
            end_view = j2s_getCurrentTime;
            is_view = 0;
          } else if (status == "end") {
            view_status = 0;
            start_view = this.start_time;
            end_view = j2s_getCurrentTime;
            is_view = 1;
          }else if (status == 'videoSeek'){
            let videoSeek_start = parseInt(live_status[0])
            let videoSeek_end = parseInt(live_status[1])
            if(videoSeek_end-videoSeek_start>0){
              view_status = 4
            }else if(videoSeek_end-videoSeek_start<0){
              view_status = 5
            }
          }


          let websocketsendParam = {
              course_id: this.course_id,
              chapter_id: this.chapter_id,
              section_id: this.sec_id,
              section_name: this.sec_name,
              uid: this.user.id,
              ua: navigator.userAgent,
              timestamp: this.timestampToTime(),
              type: view_status,
              speed: speed,
              player_current_time: j2s_getCurrentTime,
              client: 2,
              transaction_number: this.transaction_number
            }
          if(status == 'videoSeek'){
            websocketsendParam.player_current_time= parseInt(live_status[0])
            websocketsendParam.player_end_time = parseInt(live_status[1])
          }
          this.socketiosend(JSON.stringify({type:1,data:websocketsendParam}))
          // let res = await api.localFStext({
          //   param: JSON.stringify(websocketsendParam)
          // });
          obj = {
            course_id: this.course_id,
            chapter_id: this.chapter_id,
            section_id: this.sec_id,
            video_vid: realPlayStatus.vid,
            section_name: this.sec_name,
            play_minute: realPlayStatus.playduration, //播放时长
            speed: speed, //倍速
            video_minute: video_alltime, //视频时长
            start_view: start_view, // 视频观看开始事件节点
            end_view: end_view,
            view_status: view_status, // 观看状态  0 结束观看  1观看中  2 暂停观看
            section_type: this.sec_type, // 节类型
            // study_minute: realPlayStatus.playduration, // 观看时长
            // prev_time: j2s_getCurrentTime, // 本次观看进度
            is_view: is_view, // 是否看完  1   0
            res_source:2
          };
          // }
          if(this.sec.section_type == 2){
            obj.section_status = this.sec.section_status
          }
        }
      } else {
        if (live_status == "in") {
          // console.log(this.right_show_sec)
          obj = {
            course_id: this.right_show_sec.course_id,
            chapter_id: this.right_show_sec.chapter_id,
            section_id: this.right_show_sec.id,
            video_vid: "",
            section_name: this.right_show_sec.section_name,
            play_minute: 0, //播放时长
            speed: 1, //倍速
            video_minute: "", //视频时长
            start_view: "", // 视频观看开始事件节点
            end_view: "",
            view_status: 1, // 观看状态  0 结束观看  1观看中  2 暂停观看
            section_type: 2, // 节类型
            // study_minute: realPlayStatus.playduration, // 观看时长
            // prev_time: j2s_getCurrentTime, // 本次观看进度
            is_view: 0, // 是否看完  1   0
            res_source:2,
            section_status:this.right_show_sec.section_status
          };
        } else if (live_status == "out") {
          obj = {
            course_id: this.right_show_sec.course_id,
            chapter_id: this.right_show_sec.chapter_id,
            section_id: this.right_show_sec.id,
            video_vid: "",
            section_name: this.right_show_sec.section_name,
            play_minute: live_study_time, //播放时长
            speed: 1, //倍速
            video_minute: "", //视频时长
            start_view: "", // 视频观看开始事件节点
            end_view: "",
            view_status: 0, // 观看状态  0 结束观看  1观看中  2 暂停观看
            section_type: 2, // 节类型
            // study_minute: realPlayStatus.playduration, // 观看时长
            // prev_time: j2s_getCurrentTime, // 本次观看进度
            is_view: 1, // 是否看完  1   0
            res_source:2,
            section_status:this.right_show_sec.section_status,
          };
        } else {
          if(this.right_show_sec){
            obj = {
              course_id: this.right_show_sec.course_id,
              chapter_id: this.right_show_sec.chapter_id,
              section_id: this.right_show_sec.id,
              video_vid: "",
              section_name: this.right_show_sec.section_name,
              play_minute: "", //播放时长
              speed: "", //倍速
              video_minute: "", //视频时长
              start_view: "", // 视频观看开始事件节点
              end_view: "",
              view_status: "", // 观看状态  0 结束观看  1观看中  2 暂停观看
              section_type: this.right_show_sec.section_type, // 节类型
              // study_minute: realPlayStatus.playduration, // 观看时长
              // prev_time: j2s_getCurrentTime, // 本次观看进度
              is_view: 1, // 是否看完  1   0
              res_source:2,
            };
          }
          
        }
      }
      
        if (this.section_audition && this.course.is_join != 1) {
          // let res = await api.try_listen({
          //   course_id: obj.course_id,
          //   chapter_id: obj.chapter_id,
          //   section_id: obj.section_id,
          //   play_minute: obj.play_minute,
          //   res_source:2
          // });
          // if (res.code == 0) {
          // }
          obj.is_audition = 1
        }
        if(status != 'videoSeek'){
          if(obj&&obj.section_id){
            let res = await api.study(obj);
          }
        }
        
        this.is_click_sec = false;
    },
    async handleChangeTopSecNext(chapter_id, sec_id) {
      try {
        this.course.chapter.forEach((item, chapter_ind) => {
          if (item.id == chapter_id) {
            // 匹配章
            item.section.forEach((sec, sec_ind) => {
              if (sec.id == sec_id) {
                // 匹配节
                var current_chapter_length = item.section.length; // 当前章的length
                if (sec_ind + 1 < current_chapter_length) {
                  var next_sec = item.section[sec_ind + 1];
                  this.handleChangeTopSec(chapter_id, next_sec.id);
                  throw Error();
                }
              }
            });
          }
        });
      } catch (e) {}
    },
    async destroyPlayer() {
      if (player) {
        await this.study("end");
        this.studyCookie_submit()
        player.destroy();
        player = null;
      }
    },
    async destroyLivePlayer() {
      if (live_player) {
        this.study("", "out");
        // this.studyCookie_submit()
        // live_player.destroy();
        // live_player.dispose()
        live_player.pause()
        // live_player = null;
      }
    },
    changeShow(chapter_id) {
      this.time_table.forEach((item, index) => {
        if (item.id == chapter_id) {
          var isshow = item.is_show;
          this.$set(item, "is_show", !isshow);
        }
      });
    },
    async getLiveInfo(sec) {
      let _this = this;
      chat_is_join = false;
      sendMsg_flag = true;
      againconnect_flag = true;
      this.chat_list = [];
      this.send_msg_text = '';
      live_study_time = 0;
      clearInterval(live_study_timer)
      try {
        let res = await api.live_info({
          section_id: sec.id,
        });
        if (res.code == 0) {
          let {
            section_status,
            section_pull_code,
            section_chatroom,
            system_tip,
            live_notice,
            chatroom_nums,
            chatroom_add_multiple,
            chatroom_reduce_multiple,
            chapter_id,
            section_name,

            chatroom_gift,
            chatroom_speak,
            user_power,
          } = res.result;

          groupId = section_chatroom;

          // chapter_id_ = chapter_id;
          // sec_name = section_name;

          base_count = chatroom_nums;
          add_count = chatroom_add_multiple;
          reduce_count = chatroom_reduce_multiple;

          this.chatroom_gift = chatroom_gift;
          this.chatroom_speak = chatroom_speak;
          if (user_power) {
            this.user_power = user_power;
            if(user_power.is_enter!=1){
              setTimeout(()=>{
                this.$router.push('/course_info/'+this.course_id)
              },2000)
              return
            }
          } else {
            this.user_power = {
              is_enter: 1,
              is_fav: 1,
              is_gift: 1,
              is_speak: 1,
            };
          }
          _this.chat_list.push({msg_type:'welcome'});

          /* 系统提示---------------------------start */
          system_tip.forEach( (item, index) => {
            if (item.system_tips) {
              _this.chat_list.push({
                msg_type: "system_tips",
                system_tips: item.system_tips,
              });
            }
          });
          /* 系统提示---------------------------end */
          /* 公告---------------------------start */
          // if (live_notice.length) {
          this.live_notice = live_notice;
          this.time_table_tab[5].is_show = true;
          // } else {
          //   this.live_notice = [];
          //   this.time_table_tab[5].is_show = false;
          // }
          /* 公告---------------------------end */

          if (section_status == 2) {
            this.right_show_sec = sec;
            section_pull_code_player = section_pull_code
            
            this.$nextTick( () => {
              // this.live_player_render(true,section_pull_code);
              // var flag = IsPC();
              // if (flag) {
              //   var fls = flashChecker();
              //   if (fls.f) {
              //     this.live_player_render(true);
              //   } else {
              //       this.flash_tips_show = true
              //   }
              // } else {
              //   this.live_player_render(true);
              // }
              if(live_player_flag){
                // 自定义案例二：通过修改页面结构自定义ui, 更改画面中央播放按钮
                const Button = TCPlayer.getComponent('Button');
                const BigPlayButton = TCPlayer.getComponent('BigPlayButton');
                BigPlayButton.prototype.createEl = function () {
                  var el = Button.prototype.createEl.call(this);
                  // let _html = '<button><svg t="1644397862160" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3095" width="64" height="64"><path d="M298.666667 247.04V682.666667a42.666667 42.666667 0 0 1-85.333334 0V170.666667a42.666667 42.666667 0 0 1 65.024-36.352l554.666667 341.333333a42.666667 42.666667 0 0 1 0 72.704l-554.666667 341.333333a42.666667 42.666667 0 0 1-44.714666-72.704L729.258667 512 298.666667 247.04z" p-id="3096" fill="#ffffff"></path></svg></button>';
                  let _html = '<button><img class="icon" style="width:64px;" src="/tcplayer_play.png"/></button>';
                  
                  el.appendChild(TCPlayer.dom.createEl('div', {
                    className: 'vjs-button-icon',
                    innerHTML: _html,
                  }));
                  return el;
                };
                live_player = TCPlayer('player-container-id', {
                  autoplay:true,
                  controlBar:{
                    playToggle:	true, // 是否显示播放、暂停切换按钮。
                    // progressControl:true, //	是否显示播放进度条。
                    volumePanel:true, //	是否显示音量控制。
                    // currentTimeDisplay:	true, // 是否显示视频当前时间。
                    // durationDisplay:	true, // 是否显示视频时长。
                    // timeDivider:	true, //	是否显示时间分割符。
                    // playbackRateMenuButton:	true, //	是否显示播放速率选择按钮。
                    fullscreenToggle:	true, // 是否显示全屏按钮。
                    // QualitySwitcherMenuButton: true // 是否显示清晰度切换菜单。
                  },
                  languages:{
                    "zh-cn":{
                      "No video has been loaded.": "播放器没有检测到可用的视频地址。",
                      "A network error caused the media download to fail part-way.": "<p style='color:#f96158;'>老师正在加急赶来，请耐心等待！</p>",
                    }
                  }
                });
                live_player_flag = false
                this.playerOn_live()
              }
              // console.log(live_player)
              
              live_player.src("https://liveplay.successkaoyan.com/live/" +
                  section_pull_code_player +
                  ".m3u8")
              // live_player.src("https://liveplay.successkaoyan.com/live/" +
              //     section_pull_code_player +
              //     ".flv")
              // live_player.play()

              this.transaction_number = md5(this.sec_id+''+this.user.id+''+this.timestampToTime())
              let live_websocket_param = {
                  type:2,
                  data:{
                      course_id: this.course_id,
                      chapter_id: this.chapter_id,
                      section_id: this.sec_id,
                      section_name: this.sec_name,
                      uid: this.user.id,
                      ua: navigator.userAgent,
                      timestamp: this.timestampToTime(),
                      in_type:1,
                      type: this.live_is_playing?1:2,
                      client:2,
                      transaction_number:this.transaction_number,
                      data:''
                  }
              }
              this.socketiosend(JSON.stringify(live_websocket_param))
            })
            
            if(!groupId){
              this.$message("聊天室加入失败！");
              this.chat_list.push({
                msg_type: 'joinerror',
              })
              return
            }
            // this.joinGroup()
            // this.onSdkReady()
            console.log(1)
            let onSdkReady = function (event) {
              console.log(2)
              _this.joinGroup()
            };
            this.tim.on(TIM.EVENT.SDK_READY, onSdkReady); 

            live_study_timer = setInterval(()=>{
              live_study_time+=1
            },1000)
          }
          this.destroyPlayer();
          this.bg_tip_status = 2;

          await this.study("", "in");
        }
      } catch (e) {
        console.log(e);
      }
    },
    live_player_render(flash){
      console.log(flash)
      this.flash_tips_show = false
      if(flash){
        this.current_player_type = 'flash'
      }else{
        this.current_player_type = 'h5'
      }
      let _this = this
      // console.log(flash)
        // live_player = new TcPlayer("id_test_video", {
        //   m3u8:
        //     "https://liveplay.successkaoyan.com/live/" +
        //     section_pull_code_player +
        //     ".m3u8", //请替换成实际可用的播放地址
        //   flv:
        //     "https://liveplay.successkaoyan.com/live/" +
        //     section_pull_code_player +
        //     ".flv",
        //   "flash": flash,
        //   live: true,
        //   h5_flv: true,
        //   autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        //   // poster: "/static/live/images/live_nostart.png",
        //   width: "100%", //视频的显示宽度，请尽量使用视频分辨率宽度
        //   height: "100%", //视频的显示高度，请尽量使用视频分辨率高度
        //   listener: function (msg) {
        //     // console.log(msg);
        //   },
        // });
        live_player = TCPlayer('player-container-id', {
          m3u8:
            "https://liveplay.successkaoyan.com/live/" +
            section_pull_code_player +
            ".m3u8", //请替换成实际可用的播放地址
          flv:
            "https://liveplay.successkaoyan.com/live/" +
            section_pull_code_player +
            ".flv",
          flash: false,
          live: true,
          // h5_flv: true,
          autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
          // poster: "/static/live/images/live_nostart.png",
          // width: "100%", //视频的显示宽度，请尽量使用视频分辨率宽度
          // height: "100%", //视频的显示高度，请尽量使用视频分辨率高度
          listener: function (msg) {
            // console.log(msg);
          },
        });
        console.log(live_player)
        // 默认播放
        // live_player.play()
        // this.studyCookie()
        this.playerOn_live()
    },
    toChangePlayer(){
      console.log(this.current_player_type)
      console.log(live_player)
      this.destroyLivePlayer();
      if(this.current_player_type=='flash'){
        this.current_player_type='h5'
        this.live_player_render(false)
      }else{
        this.current_player_type='flash'
        var fls = flashChecker();
        if (fls.f) {
          this.live_player_render(true);
        } else {
            this.flash_tips_show = true
        }
      }
      
    },
    async joinGroup(){
      console.log('加入群组---------------------')
      let _this = this
      this.tim.joinGroup({
        groupID: groupId,
        type: TIM.TYPES.GRP_AVCHATROOM,
      }).then(function (imResponse) {
          console.log(imResponse)
          let isSDKReady = TIM.EVENT.SDK_READY ? true : false
          switch (imResponse.data.status) {
            case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
              break;
            case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
              // console.log(imResponse.data.group); // 加入的群组资料
              if(isSDKReady){
                _this.tim.getMyProfile().then(function(imResponse) {
                  // console.log(imResponse.data); // 个人资料 - Profile 实例
                  let {profileCustomField} = imResponse.data
                  if(profileCustomField&&profileCustomField.length){
                    profileCustomField.forEach((item,index)=>{
                      if(item.key=='Tag_Profile_Custom_RealName'){
                            real_name = item.value
                      }
                    })
                  }

                  chat_is_join = true
                  _this.changePersonCount(1);
                  _this.selectCustomEmotionImg("", 3001, "");

                }).catch(function(imError) {
                  console.warn('getMyProfile error:', imError); // 获取个人资料失败的相关信息
                });
              }
              break;
            case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
              if(isSDKReady){
                _this.tim.getMyProfile().then(function(imResponse) {
                  // console.log(imResponse.data); // 个人资料 - Profile 实例
                  let {profileCustomField} = imResponse.data
                  if(profileCustomField&&profileCustomField.length){
                    profileCustomField.forEach((item,index)=>{
                      if(item.key=='Tag_Profile_Custom_RealName'){
                            real_name = item.value
                      }
                    })
                  }

                  chat_is_join = true
                  _this.changePersonCount(1);
                  _this.selectCustomEmotionImg("", 3001, "");

                }).catch(function(imError) {
                  console.warn('getMyProfile error:', imError); // 获取个人资料失败的相关信息
                });

              }
              break;
            default:
              break;
          }
        })
        .catch(function (imError) {
          // _this.$message("聊天室加入失败！11");
          _this.chat_list.push({
            msg_type: 'joinerror',
          })
          console.warn("joinGroup error:", imError); // 申请加群失败的相关信息
        });
    },
    selectCustomEmotionImg(selImg, customType, uploadImg) {
      var _this = this;
      // 自定义消息id  类型  上传图片src
      let { pname, chat_only_code, id, userlogo } = this.user;
      var option = {};
      if (customType == 3001 || customType == 3002) {
        // 进入直播间 | 退出直播间
        option = {
          type: customType,
          pname: pname,
          chat_only_code: chat_only_code,
          client: client,
          uid: id,
        };
      } else if (customType == 3009) {
        option = {
          type: customType,
          pname: pname,
          chat_only_code: chat_only_code,
          client: client,
          uid: id,
        };
      } else if (customType == 3003) {
        option = {
          type: customType,
          pname: pname,
          chat_only_code: chat_only_code,
          client: client,
          uid: id,
          big_index: selImg,
        };
      }
      if(!chat_is_join){
        this.$message("聊天室加入失败！");
        this.chat_list.push({
            msg_type: 'joinerror',
          })
        return
      }
      // 2. 创建消息实例，接口返回的实例可以上屏
      let customMessage = this.tim.createCustomMessage({
        to: groupId,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          data: JSON.stringify(option),
          description: "800", // 获取骰子点数
          extension: "",
        },
        cloudCustomData:JSON.stringify({real_name:real_name})
      });
      // 3. 发送消息
      console.log(customMessage)
      this.tim
        .sendMessage(customMessage)
        .then(function (imResponse) {
          // 发送成功
          if (imResponse.code == 0) {
            _this.renderMsg(imResponse.data.message);
            let websocketsendParam = {}
            if(customType==3009){
              websocketsendParam = {
                  type:2,
                  data: {
                      course_id: _this.course_id,
                      chapter_id: _this.chapter_id,
                      section_id: _this.sec_id,
                      section_name: _this.sec_name,
                      uid: _this.user.id,
                      ua: navigator.userAgent,
                      timestamp: _this.timestampToTime(),
                      type: -1,
                      client:2,
                      transaction_number:_this.transaction_number,
                      in_type:3,
                      data:{
                          msg_type: "TIMCustomElem",
                          type: 3009,
                          pname: pname,
                          userlogo: userlogo
                      }
                  }
              }
            }else if(customType==3003){
              websocketsendParam = {
                  type:2,
                  data: {
                      course_id: _this.course_id,
                      chapter_id: _this.chapter_id,
                      section_id: _this.sec_id,
                      section_name: _this.sec_name,
                      uid: _this.user.id,
                      ua: navigator.userAgent,
                      timestamp: _this.timestampToTime(),
                      type: -1,
                      client:2,
                      transaction_number:_this.transaction_number,
                      in_type:3,
                      data:{
                          msg_type: "TIMCustomElem",
                          type: 3003,
                          pname: pname,
                          userlogo: userlogo,
                          big_index: selImg
                      }
                  }
              }
            }
            if(JSON.stringify(websocketsendParam)!='{}'){
              _this.socketiosend(JSON.stringify(websocketsendParam))
            }
          }
        })
        .catch(function (imError) {
          _this.$message("消息发送失败！");
          console.warn("sendMessage error:", imError);
        });
    },
    renderMsg(msg) {
      if (msg.to == groupId) {
        // 判断聊天室
        if (msg.status == "success") {
          // console.log(msg);
          if (msg.type == "TIMCustomElem") {
            var custom_msg = JSON.parse(msg.payload.data);
            // console.log(custom_msg);
            if (custom_msg.type == 3001) {
              // 加入聊天室
              if (msg.flow == "in") {
                // 接受消息
                this.hot_count = this.hot_count + add_count;
              }
              this.chat_list.push({
                msg_type: msg.type,
                type: custom_msg.type,
                pname: custom_msg.pname,
              });
            } else if (custom_msg.type == 3002) {
              // 离开聊天室
              // this.hot_count = this.hot_count - reduce_count;
              // this.chat_list.push({
              //   msg_type: msg.type,
              //   type: custom_msg.type,
              //   pname: custom_msg.pname,
              // });
            } else if (custom_msg.type == 3003) {
              // 动图
              var img_url = custom_emotions[custom_msg.big_index];

              if (msg.flow == "out") {
                this.chat_list.push({
                  msg_type: msg.type,
                  type: custom_msg.type,
                  pname: this.user.pname,
                  userlogo: this.user.userlogo,
                  flow: msg.flow,
                  img_url: img_url,
                });
              } else {
                this.chat_list.push({
                  msg_type: msg.type,
                  type: custom_msg.type,
                  pname: msg.nick,
                  userlogo: msg.avatar,
                  flow: msg.flow,
                  img_url: img_url,
                });
              }
            } else if (custom_msg.type == 3004) {
              //直播间禁言
              this.chatroom_speak = 0;
              this.chat_list.push({
                msg_type: msg.type,
                type: custom_msg.type,
              });
            } else if (custom_msg.type == 3005) {
              //直播间禁言 解除
              this.chatroom_speak = 1;
              this.chat_list.push({
                msg_type: msg.type,
                type: custom_msg.type,
              });
            } else if (custom_msg.type == 3006) {
              //禁言某人
              if (custom_msg.to_imid == this.user.chat_only_code) {
                this.user_power.is_speak = 0;
                this.chat_list.push({
                  msg_type: msg.type,
                  type: custom_msg.type,
                });
              }
            } else if (custom_msg.type == 3007) {
              //禁言某人（解除）
              if (custom_msg.to_imid == this.user.chat_only_code) {
                this.user_power.is_speak = 1;
                this.chat_list.push({
                  msg_type: msg.type,
                  type: custom_msg.type,
                });
              }
            } else if (custom_msg.type == 3008) {
              //踢人
              if (custom_msg.to_imid == this.user.chat_only_code) {
                // $(".marking_kick").fadeIn();
                this.user_power.is_enter = 0;
                setTimeout(()=>{
                  this.$router.push('/course_info/'+this.course_id)
                }, 2000);
                this.chat_list.push({
                  msg_type: msg.type,
                  type: custom_msg.type,
                });
              }
            } else if (custom_msg.type == 3009) {
              // 送花
              this.chat_list.push({
                msg_type: msg.type,
                type: custom_msg.type,
                pname: custom_msg.pname,
              });
            } else if (custom_msg.type == 3010) {
              // 直播结束
              this.live_ending = true;
              setTimeout( ()=> {
                 this.$router.push('/course_info/'+this.course_id)
              }, 2000);
            } else if (custom_msg.type == 3011) {
              if(custom_msg.notice){
                // 聊天室公告
                this.chat_list.push({
                  msg_type: msg.type,
                  type: custom_msg.type,
                  content: custom_msg.notice,
                });

                // 公告
                this.live_notice.unshift(custom_msg);
              }
            }
          } else if (msg.type == "TIMTextElem") {
            if (msg.flow == "out") {
              // 发出消息
              this.chat_list.push({
                msg_type: msg.type,
                pname: this.user.pname,
                userlogo: this.user.userlogo,
                flow: msg.flow,
                text: parseText(msg.payload.text),
              });
            } else {
              // 接收消息
              this.chat_list.push({
                msg_type: msg.type,
                pname: msg.nick,
                userlogo: msg.avatar,
                flow: msg.flow,
                text: parseText(msg.payload.text),
              });
            }
          }
        }
      }
    },
    async changePersonCount(type) {
      let islogin = await this.islogin();
      if(islogin){
        try {
          let res = await api.census({
            section_id: this.sec_id,
            type: type,
            source: 2,
          });
          if (res.code == 0) {
            let people_true = Number(res.result);
            if (type == 1) {
              this.hot_count = base_count + people_true * add_count;
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    onSendMsg() {
      var _this = this;
      var send_msg = this.send_msg_text.replace(/^\s+|\s+$/g, "");
      if (send_msg == "") {
        this.$message("请输入您的聊天内容！");
        return;
      }

      if(!chat_is_join){
        this.$message("聊天室加入失败！");
        _this.chat_list.push({
            msg_type: 'joinerror',
          })
        return
      }

      if(!sendMsg_flag){
        this.$message("请勿连续发消息！");
        return
      }
      sendMsg_flag = false
      setTimeout(()=>{
        sendMsg_flag = true
      },2000)

      let textMessage = this.tim.createTextMessage({
        to: groupId,
        conversationType: TIM.TYPES.CONV_GROUP,
        // 消息优先级，用于群聊（v2.4.2起支持）。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息，详细请参考：https://cloud.tencent.com/document/product/269/3663#.E6.B6.88.E6.81.AF.E4.BC.98.E5.85.88.E7.BA.A7.E4.B8.8E.E9.A2.91.E7.8E.87.E6.8E.A7.E5.88.B6)
        // 支持的枚举值：TIM.TYPES.MSG_PRIORITY_HIGH, TIM.TYPES.MSG_PRIORITY_NORMAL（默认）, TIM.TYPES.MSG_PRIORITY_LOW, TIM.TYPES.MSG_PRIORITY_LOWEST
        // priority: TIM.TYPES.MSG_PRIORITY_NORMAL,
        payload: {
          text: send_msg,
        },
        cloudCustomData:JSON.stringify({real_name:real_name})
      });
      // 2. 发送消息
      this.tim
        .sendMessage(textMessage, {
          offlinePushInfo: {
            disablePush: true, // 如果接收方不在线，则消息将存入漫游，但不进行离线推送
          },
        })
        .then(function (imResponse) {
          // 发送成功 渲染消息
          if (imResponse.code == 0) {
            _this.renderMsg(imResponse.data.message);
            _this.send_msg_text = "";
            let websocketsendParam = {
                type:2,
                data: {
                    course_id: _this.course_id,
                    chapter_id: _this.chapter_id,
                    section_id: _this.sec_id,
                    section_name: _this.sec_name,
                    uid: _this.user.id,
                    ua: navigator.userAgent,
                    timestamp: _this.timestampToTime(),
                    type: -1,
                    client:2,
                    transaction_number:_this.transaction_number,
                    in_type:3,
                    data:{
                        msg_type: "TIMTextElem",
                        pname: _this.user.pname,
                        userlogo: _this.user.userlogo,
                        text: send_msg
                    }
                }
            }
            _this.socketiosend(JSON.stringify(websocketsendParam))
          }
        })
        .catch(function (imError) {
          _this.$message("消息发送失败！");
          console.warn("sendMessage error:", imError);
        });
    },
    onSendMsgKeyUp(event) {
      if (event.keyCode == 13) {
        if (!event.metaKey) {
          event.preventDefault();
          this.onSendMsg();
        } else {
          this.send_msg_text = this.send_msg_text + "\n";
        }
      }
    },
    handleClickEmotion(key) {
      this.send_msg_text = this.send_msg_text + key;
      this.emoji_pannel_ishow = false;
    },
    handleClickGif(key) {
      this.selectCustomEmotionImg(key, 3003);
      this.emoji_pannel_ishow = false;
      this.emoji_pannel_index = 0;
    },
    handleTrim(str) {
      return str.replace(/^\s+|\s+$/g, "");
    },
    handleClickFlower() {
      if(!chat_is_join){
        this.$message("聊天室加入失败！");
        this.chat_list.push({
            msg_type: 'joinerror',
          })
        return
      }
      var _this = this;
      this.flower_time = 5;
      flower_timer = setInterval( () =>{
        this.flower_time--;
        if (this.flower_time == 0) {
          clearInterval(flower_timer);
        }
      }, 1000);

      this.selectCustomEmotionImg("", 3009, "");
    },
    handleChangeEmojiPannel() {
      this.emoji_pannel_ishow = !this.emoji_pannel_ishow;
    },
    handleRightHide() {
      if (this.left_show_type == "live" && this.sec.section_status == 2) {
        this.right_show_type = "chat";
      } else {
        this.right_show_type = "";
      }
      this.right_show_sec = "";
    },
    async getHomeWorkDetail() {
      try {
        let res = await api.homework_detail({
          course_id: this.course_id,
          chapter_id: this.right_show_sec.chapter_id,
          task_id: this.right_show_sec.sectionTask.id,
        });
        if (res.code == 0) {
          this.homework_detail = res.result;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleHomeworkNext(){
      this.handleChangeTopSecNext(this.right_show_sec.chapter_id,this.right_show_sec.id)
    },
    // 计算pdf页码总数
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.pdf_url);
      loadingTask.promise
        .then((pdf) => {
          this.numPages = pdf.numPages;
        })
        .catch((err) => {
          console.error("pdf 加载失败", err);
        });
    },

    islogin() {
      this.setUserAndState();
      const { logined } = this.$store.state;
      if (logined) {
        return true;
      } else {
        return false;
      }
    },
    async getSign(vid) {
      if(vid&&vid!=0){
        try {
          let res = await api.ticket({
            vid: vid,
          });
          if (res.code == 0) {
            return res.result;
          } else {
            uni.showToast({
              title: res.message,
              duration: 2000,
              icon: "none",
            });
          }
        } catch (e) {
          console.log(e);
        } 
      }else{
        // 视频没有vid
        this.destroyPlayer();
        this.left_show_type = "play";
        this.bg_tip_status = 1;
        return
      }
    },
    drapContent() {
      var resize = document.getElementById("webide-drag");
      var left = document.getElementById("col-left");
      var mid = document.getElementById("col-right");
      var box = document.getElementById("webide-layout");

      resize.onmousedown = function (e) {
        // resize.style.background = "#818181";
        var startX = e.clientX;
        resize.left = resize.offsetLeft;
        document.onmousemove = function (e) {
          var endX = e.clientX;
          var moveLen = resize.left + (endX - startX); // （endx-startx）=移动的距离。resize.left+移动的距离=左边区域最后的宽度
          var maxT = box.clientWidth - resize.offsetWidth; // 容器宽度 - 左边区域的宽度 = 右边区域的宽度

          if (moveLen < 500) moveLen = 500; // 左边区域的最小宽度为500px
          if (moveLen > maxT - 300) moveLen = maxT - 300; //右边区域最小宽度为150px

          // resize.style.left = moveLen; // 设置左侧区域的宽度

          left.style.width = moveLen + "px";
          mid.style.width = box.clientWidth - moveLen - 10 + "px";
        };
        // 鼠标松开事件
        document.onmouseup = function (evt) {
          //颜色恢复
          // resize.style.background = "#d6d6d6";
          document.onmousemove = null;
          document.onmouseup = null;
          resize.releaseCapture && resize.releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
        };
        resize.setCapture && resize.setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
        return false;
      };
    },
    toOrder() {
      var base64_checked_str = Base64.encode(
        JSON.stringify([
          {
            good_id: this.course.good_id,
            num: 1,
          },
        ])
      );
      this.$router.push(`/order_info/${base64_checked_str}`);
    },
    async handleClickNotice(notice_id) {
      try {
        let res = await api.confirm({ id: notice_id });
        if (res.code == 0) {
          this.live_notice.forEach((item, index) => {
            if (item.id == notice_id) {
              this.$set(item, "user_confirm", 1);
            }
          });
          this.$notify({
            title: "社科赛斯考研提醒您",
            message: "确认成功",
            type: "success",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    getImageList(url){
      let url_arr = url.map(item => {
        return item.src;
      });
      return url_arr
    },
    closeImg(index){
      this.homework_img.splice(index, 1);
    },
    async handleSubmitHomework(){
      var len = this.homework_img.length
      if(len==0){
        this.$message({
          message:'请上传作业',
          type: 'warning'
        })
        return;
      }
      try {
        let res = await api.task_submit({
          course_id:this.course_id,
          chapter_id:this.chapter_id,
          section_id:this.right_show_sec.id,
          task_id:this.right_show_sec.sectionTask.id,
          task_content:JSON.stringify(this.homework_img)
        });
        if (res.code == 0) {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
          this.handleHomeworkStatus(1)
          this.getHomeWorkDetail()
        }else{
          this.$message.error('提交失败');
        }
      } catch (e) {
        console.log(e);
      }
    },
    handleHomeworkStatus(status){
      this.homework_show_status=status

      // 如果要去上传作业
      if(status==2){
        if(this.homework_detail.record){
          this.homework_img = this.homework_detail.record.task_content
        }
      }
      if(status==3){
        this.$nextTick(()=>{
          // 监听滚动事件
          document.getElementById('resultScroll').addEventListener('scroll', this.handleScroll, true)
        })
        

        this.homework_page =1
        this.get_task_list(this.homework_page,this.homework_limit,false)
      }
      
    },
    uploadUserLogo() {
      var u_input = document.getElementById("fileinput");
      if (!u_input) {
        var u_input = document.createElement("input");
        u_input.type = "file";
        u_input.id = "fileinput";
        u_input.style.position = "absolute";
        u_input.style.left = "-999px";
        u_input.style.top = "-999px";
        u_input.accept = "image/*";
        u_input.multiple = "multiple";
        this.$refs.Submit_job.appendChild(u_input);
      }
      u_input.onchange = (event) => {
        var files = event.path[0].files;
        this.uploadCos(files);
      };
      document.getElementById("fileinput").click();
    },
    uploadCos(files) {
      console.log(files);
      var files_arr = [];
      files.forEach((item) => {
        var nowTime_s = new Date().getTime();
        var random_num = randomWord(false, 15);
        // var suffix = item.name.split('.')[1]
        var index = item.name.lastIndexOf(".");
        var suffix = item.name.substr(index + 1);
      
        files_arr.push({
          Bucket: Bucket,
          Region: Region,
          Key:
            "task/" +
            timeimg +
            "/" +
            nowTime_s +
            random_num +
            "." +
            suffix /* 必须 */,
          Body: item,
        });
      });
      let _this = this;
      cos.uploadFiles(
        {
          files: files_arr,
          onProgress: function (info) {
          },
          onFileFinish: function (err, data, options) {
            // console.log(options.Key + '上传' + (err ? '失败' : '完成'))
          },
        },
        function (err, data) {
          // console.log(err || data);
          if (data) {
            data.files.forEach(function (item, index) {
            var img_url= 'https://'+item.data.Location
            var img = new Image();
            img.src = img_url;
            img.onload = function(){
              if(_this.homework_img.length<4){
                _this.homework_img.push({
                  src:img_url,
                  width:img.width,
                  height:img.height
                });
              }else{
                _this.$message({
                  message:'最多上传4张',
                  type: 'warning'
                })
              }
            };
            });
          }
        }
      );
    },
    async get_task_list(page, limit, push = true){
      try{
        let res = await api.task_list({
          course_id: this.course_id,
          task_id: this.right_show_sec.sectionTask.id,
          page:page,
          limit:limit
        })
        if(res.code==0){
          let new_data = res.result.data
          if(push){
            if(new_data.length>0){
              this.homework_list = this.homework_list.concat(new_data)
              this.homework_page +=1
            }
          } else {
            let page = 1;
            if (new_data.length > 0) {
              page = page + 1;
            } 
            this.homework_list = new_data;
            this.homework_page = page;
          }
        }else{
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      }catch(e){
        console.log(e)
      }
    },
    handleScroll () {
      let sh = this.$refs['myScrollbar'].scrollHeight // 滚动条高度
      let st = this.$refs['myScrollbar'].scrollTop // 滚动条距离顶部的距离
      let ch = this.$refs['myScrollbar'].clientHeight // 滚动条外容器的高度
      if (st + ch >= sh) {
        //到底了 业务逻辑
        this.get_task_list(this.homework_page,this.homework_limit,true)
        // this.page.pageIndex += 1
        // if (this.pageIndex <= this.total) {
        //   this.onSearch()
        // }
      }
    },
    getLevelUrl(level){
	  	if(level==0){
	  		return ''
	  	}else if(level==1){
	  		return require('@/assets/homework/a+.png')
	  	}else if(level==2){
	  		return require('@/assets/homework/a.png')
	  	}else if(level==3){
	  		return require('@/assets/homework/b+.png')
	  	}else if(level==4){
	  		return require('@/assets/homework/b.png')
	  	}else if(level==5){
	  		return require('@/assets/homework/c+.png')
	  	}else if(level==6){
	  		return require('@/assets/homework/c.png')
	  	}
	  },
    handleToInfo(){
      this.$router.push('/course_info/'+this.course_id)
    },
    login_out() {
      this.DialogVisible = true;
    },
    async handleLoginOut() {
      await this.destroyPlayer();
      await this.destroyLivePlayer();
      if(this.left_show_type=='live'){
        await this.changePersonCount(2);
      }
      await this.tim.logout();

      let res = await api.sign_out();
      this.DialogVisible = false;
      clearCookie("jst");
      clearCookie("logined");
      clearCookie("userInfo");
      this.setUserAndState();
      this.$message({
        message: "退出成功",
        type: "success",
        duration: 500,
        onClose: () => {
          this.$router.push({
            path: `/`,
          });
        },
      });
    },
    kickedOutReason(type) {
      switch (type) {
        case this.TIM.TYPES.KICKED_OUT_MULT_ACCOUNT:  // 由于多实例登录
          return "您已在其他页面观看";
        case this.TIM.TYPES.KICKED_OUT_MULT_DEVICE:  // 由于多设备登录
          return "您已在其他设备观看";
        case this.TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED: // 由于 userSig 过期
          return "由于 userSig 过期";
        default:
          return "";
      }
    },
    async handleAgainConnect(){
      if(!againconnect_flag){
        this.$message("请稍后再试！");
        return
      }
      againconnect_flag = false
      this.chat_list.push({
        msg_type: 'connecting',
      })

      setTimeout(()=>{
        againconnect_flag = true
      },5000)
      let res = await api.re_live({section_id:this.sec_id})
      if(res.code==0){
        if(res.result){
          groupId= res.result
          this.joinGroup()
        }else{
          this.chat_list.push({
            msg_type: 'joinerror',
          })
        }
      }
    },
    handleToPage(url){
      this.$router.replace(url)
    },
    pdfDownload(){
      
    }
  },
  computed: {},
  watch: {
    chat_list: function () {
      let len = this.chat_list.length
      // console.log(this.chat_list)
      // console.log('chat_list的length-----'+len)
      if(len>300){
        this.chat_list.shift()
      }
      this.$nextTick(() => {
        let chatRoom = this.$refs.chatList;
        chatRoom.scrollTop = chatRoom.scrollHeight;
      });
    },
    left_show_type: function (newVal, oldVal) {
      if (newVal) {
        if (this.right_show_type) {
          this.$nextTick(() => {
            this.drapContent();
          });
        }
      }
    },
    right_show_type: function (newVal, oldVal) {
      if (newVal) {
        if (this.left_show_type) {
          this.$nextTick(() => {
            this.drapContent();
          });
        }
      }
    },
  },
  filters: {
    add0(num) {
      if (num < 10) {
        return "0" + num;
      } else {
        return num;
      }
    },
  },
};
</script>
<style>
.chat_b .msg .face_img {
  width: 20px;
  padding: 0 2px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}
</style>
<style lang="less" scoped>
@import "../assets/course/course_watch.css";
.againconnect{
  color: #FF8A00;
  padding-left: 10px;
}
</style>
